import React, {useEffect} from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

function Guarantee() {
  useEffect(() => {
    document.title = 'Amani';
    window.scrollTo(0, 0);

}, [])
  return (
    <>
      <Header />
      <div className="container my-5">
        <div className="row">
            <div className="col-12">
                <h3 className='text-center mb-5 fw-bold text-decoration-underline text-decoration-offset-1'>AMANI MATTRESS GUARANTEE / WARRANTY TERMS</h3>
                <ul className='ul-list'>
                    <li>The guarantee / warranty applies only if the purchase of the mattress from an authorised
                    dealer.</li>
                    <li>To make a guarantee / warranty claim, the card and relevant cash memo must be produced
                    along with the mattress.</li>
                    <li>Please note that stains occurring on the ma􀆩ress tapestry ater unpacking or removing the
                    packing/poly cover and subsequent use at the customer’s end will not be covered under the
                    guarantee / warranty.</li>
                    <li>Opening of stitches (both quilt and tape edge) that is reported ater 15 days of the purchase
                    date will not be covered under the warranty. Cloth damage or tearing of cloth during usage
                    will not be covered under the guarantee / warranty.</li>
                    <li>If a warranty claim is made, the product needs to be brought to the point of purchase.</li>
                    <li>We will do our best to replace the defective mattress at the earliest, the actual 􀆟me
                    required for replacement will depend on the availability of the product.</li>
                    <li>If the mattress that has been purchased is discontinued, a new mattress in the same price
                    range as the original mattress may be given under the replacement terms.</li>
                    <li>The rebate will be calculated on the MRP as of the date of exchange.</li>
                    <li>Please note that our guarantee / warranty doesn’t include damage resulting from improper
                    usage.</li>
                    <li>Damages due to infesta􀆟on by insects/rodents/rats and due to any natural calamities are not
                    covered under the guarantee / warranty.</li>
                    <li>Damages or sagging in the mattress due to improper size and condition of the cot, uneven
                    resting surface, and improper structural design of the cot are not covered.</li>
                    <li>Guarantee / Warranty is not applicable for any smell that may occur during usage in an
                    enclosed environment, moisture in the air due to the surrounding environment /use of air
                    condi􀆟oner, excessively wet surface, and pouring of liquid on the mattress.</li>
                    <li>Please note that when the ma􀆩ress is used initially, there may be a slight depression as the
                    mattress conforms to your body shape. This is normal and in no way will it reduce your
                    comfort level.</li>
                    <li>Claims arising from the guarantee / warranty will be restricted to courts within Siliguri
                    Jurisdiction only.</li>
                    <li>Kindly note that the decision of company regarding nature of defects and applicability of this
                    guarantee / warranty will be final.</li>
                </ul>
            </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Guarantee
