import React, {useState} from 'react';
import Header from '../components/Header';
import mainImage from '../assets/img/Sofa Cum Bed/DSL16091.jpg';
import thumb1 from '../assets/img/Sofa Cum Bed/sofa-cum-bed (2).jpg';
import thumb2 from '../assets/img/Sofa Cum Bed/sofa-cum-bed.jpg';
import thumb3 from '../assets/img/Sofa Cum Bed/sofa-cum-bed (3).jpg';
import '../assets/css/productview.css';
import Footer from '../components/Footer';


const images = [
    { src: mainImage, alt: 'Main Image' },
    { src: thumb1, alt: 'Thumbnail 1' },
    { src: thumb2, alt: 'Thumbnail 2' },
    { src: thumb3, alt: 'Thumbnail 3' },
  ];

function SofaCumBed() {

    const [mainImage, setMainImage] = useState(images[0]);
    const [size, setSize] = useState('');
  
    const handleImageClick = (image) => {
      setMainImage(image);
    };
    const handleSizeChange = (event) => {
      setSize(event.target.value)
    } 
  
    const handleOrderNow = () => {
      if (size) {
        const message = `I would like to order the size: ${size}`;
        const whatsappUrl = `https://wa.me/9933480777?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl);
        console.log(message);
      } else {
        alert("Please select a size before ordering.");
      }
    };

  return (
    <> 
        <Header />

        <div className="container mt-5 d-flex product-details">
            <div className="gallery">
                <div className="thumbnails me-5">
                {images.map((image, index) => (
                    <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    onClick={() => handleImageClick(image)}
                    className={mainImage.src === image.src ? 'active' : ''}
                    />
                ))}
                </div>

                <div className="main-image me-5">
                <img src={mainImage.src} alt={mainImage.alt} className='img-fluid'/>
                </div>

            </div>

            <div className="details">
                <h2 className='fw-bolde'>28D Sofa Cum Bed</h2>
                <p className='font-size-1 mb-0'>Luxury Line</p>
                <select value={size} onChange={handleSizeChange} className='select-custom'>
                <option value=''>Size Available</option>
                <option value="45x24x4 Inch">45x24x4 Inch</option>
                </select>
                <p>Selected Size: {size}</p>
                <div className="row">
                <div className="col-6 features">
                    <p>Breathable</p>
                </div>
                <div className="col-6 features">
                    <p>Waterproof</p>
                </div>
                <div className="col-6 features">
                <p>Ultra Durable</p>
                </div>
                <div className="col-6 features">
                    <p>Ultra Soft</p>
                </div>

                </div>
                <button onClick={handleOrderNow} className='btn-purple text-white mt-7'>Order Now</button>
            </div>
        </div>
        <div className="container mt-7 mb-7">
            <h2 className='font-size-1 fw-600'>Additional Information</h2>
            <hr className='hr-yellow'/>
            <p className='font-size-1'>Crafted out of safe materials, the baby mattress is hypo allergenic and gentle on your baby's skin. It's antimicrobial
                nature keeps the dust mites away, ensuring your baby is
                happy and well-slept.
            </p>
        </div>
        <Footer />
    </>
  )
}

export default SofaCumBed
