import React, {useEffect}  from 'react';
// import Filter from '../components/Filter';
import MattressTabs from '../components/MattressTabs';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Mattress() {
  useEffect(() => {
    document.title = 'Mattress - Amani';
    window.scrollTo(0, 0);
  })
    
  return (
    <>
    <Header />
      <div className="mattress-poster"></div>
      <div className="container mt-7 mb-7">
        <div className="row">   
            {/* <div className="col-md-3 col-sm-12">
                <Filter />
            </div> */}
            {/* cards */}
            <div className="col-md-12 col-sm-12">
              <p className='font-size-2 fw-600 custum-text-primary text-center'>Mattresses</p>
              <MattressTabs />
            </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Mattress
