import React, {useEffect} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function TermsCondition() {
    useEffect(() => {
        document.title = 'Amani';
        window.scrollTo(0, 0);

    }, [])

  return (
    <>
      <Header />
      <div className="container my-5">
        <div className="row">
            <div className="col-12">
                <h2 className='text-center mb-5 fw-bold text-decoration-underline text-decoration-offset-1'>AMANI MATTRESS TERMS & CONDITIONS</h2>
                <h3 className='pb-3'>PRODUCT DELIVERY TERMS & CONDITIONS</h3>
                <ol>
                    <li className='mb-2'>The delivery dates for a product may vary based on stock availability.</li>
                    <li>Product cancellation must be reported within 12 hours of purchase confirma􀆟on.</li>
                </ol>
                <h3 className='py-3'>EXCEPTIONS TO OUR POLICIES (WARRANTY):</h3>
                <ol>
                    <li className='mb-2'>Misused products or tampered products will not be eligible for returns</li>
                    <li className='mb-2'>Mattress size will vary +/-12 mm in length, width & thickness. Users should allow a minimum
                    of 72 hours for the mattress as per the instruction provided so that the mattress will regain
                    its original shape. Any size variation reported after five days from the date of purchase will
                    not be covered under warranty terms.</li>
                    <li className='mb-2'>A mattress that shall be returned will be in good condition without any fabric damages such
                    as tears, stains, colour fading, bleeding, soil and burns.</li>
                    <li className='mb-2'>The original invoice with the company’s seal and signature has to be presented for all return
                    requests.</li>
                    <li>The cost of reverse shipping will be borne by AMANI as long as the product has stayed on the
                    premises of the original delivery loca􀆟on. In all other cases, the customer will pay the
                    differential shipping charges.</li>
                </ol>
                <h3 className='py-3'>SHIPPING & TRANSPORTATION:</h3>
                <p>The company takes reasonable care to pack and ship the products safely; the sale is complete once
                the goods are delivered to the customer. If the goods are delivered in damaged condition, the
                customer has to inform the company immediately.</p>
                <h3 className='py-3'>APPLICABLE LAW:</h3>
                <p>All sales and interactions with AMANI FURNITURE PVT LTD shall be governed by, interpreted, and
                construed by the laws of India. In case of any disputes with AMANI FURNITURE PVT LTD, the same
                shall be subject to courts/authorites/forums in SILIGURI only.</p>
                <h3 className='py-3'>TERMS AND CONDITIONS:</h3>
                <p>This page states the Terms and Conditions under which you (Visitor) may visit this Website. Please
                read this page carefully. If you do not accept the Terms and Conditions stated here, we will request
                you to exit this site. The business, any of its business divisions and its subsidiaries, associate
                companies or subsidiaries to subsidiaries or other investment companies (in India or abroad) reserve
                their respective rights to revise these Terms and Condi􀆟ons by updating this pos􀆟ng. You should visit
                this page periodically to re-appraise yourself to the Terms and Conditions because they are binding
                on all users of this Website.</p>
                <h3 className='py-3'>USE OF CONTENT:</h3>
                <p>All logos, brands, marks headings, labels, names, signatures, numerals, shapes or any combina􀆟ons
                appearing on this site, except as otherwise noted, are properties owned or used under license by the
                business and/or its associate entities who feature on this Website. The use of these properties or any other content on this site, except as provided in these terms and condi􀆟ons or the site content, is
                strictly prohibited. You may not sell or modify the content of this Website or reproduce, display,
                publicly perform, distribute, or otherwise use the materials in any way for any public or commercial
                purpose without the respective organisation’s or entity’s written permission.</p>
                <h3 className='py-3'>ACCEPTABLE WEBSITE USE:</h3>
                <p>Security Rules Visitors are prohibited from viola􀆟ng or atempting to violate the security of the
                Website, including, without limitation:</p>
                <ol>
                    <li className='mb-2'>Accessing data not intended for such user or logging into a server or account which the user
                    is not authorised to access.</li>
                    <li className='mb-2'>Attempting to probe, scan or test the vulnerability of a system or network or to breach
                    security or authentication measures without proper authorisation.</li>
                    <li className='mb-2'>Attempting to interfere with service to any user, host or network, including, without
                    limitation, via means of submitting a virus or “Trojan horse” to the Website, overloading,
                    “flooding”, “mail bombing”, or “crashing.”</li>
                    <li>Sending unsolicited electronic mail, including promo􀆟ons and/or advertising of products or
                    services. Violations of system or network security may result in civil or criminal liability. The
                    business and/or its associate entities will have the right to investigate occurrences they
                    suspect as involving such violations and will have the right to apply and cooperate with law
                    enforcement authorities in prosecuting users involved in such violations.</li>
                </ol>
                <h3 className='py-3'>General Rules Visitors may not use the Web Site to transmit, distribute, store or destroy material:</h3>
                <ol>
                    <li className='mb-2'>That could constitute or encourage conduct considered a criminal offence or violate
                    applicable law or regulation.</li>
                    <li className='mb-2'>In a manner that will infringe the copyright, trademark, trade secret or other intellectual
                    property rights of others or violate the privacy or publicity of other personal rights of others.</li>
                    <li>That is libellous, defamatory, pornographic, profane, obscene, threatening, abusive or
                    hateful.</li>
                </ol>
                <h3 className='py-3'>INDEMNITY:</h3>
                <p>The User unilaterally agrees to indemnify and hold harmless, without objection, the Company, its
                officers, directors, employees and agents from and against any claims, actions and/or demands
                and/or liabilities and/or losses and/or damages whatsoever arising from or resulting from their use
                of amani mattress.com or their breach of the terms.</p>
                <h3 className='py-3'>LIABILITY:</h3>
                <ul className='ul-list'>
                    <li>User agrees that neither Company nor its group companies, directors, officers or employee
                    shall be liable for any direct or/and indirect or/and incidental or/and special or/and
                    consequential or/and exemplary damages resulting from the use or/and the inabulity to use the service or/and for the cost of procurement of subs􀆟tute goods or/and services or
                    resulting from any goods or/and data or/and information or/and services purchased or/and
                    obtained or/and messages received or/and transactions entered into through or/and from
                    the service or/and resulting from unauthorised access to or/and alteration of user’s
                    transmissions or/and data or/and arising from any other matter relating to the service,
                    including but not limited to, damages for loss of profits or/and use or/and data or other
                    intangible, even if Company has been advised of the possibility of such damages.</li>
                    <li>User further agrees that Company shall not be liable for any damages arising from
                    interruption, suspension or termination of service, including but not limited to direct or /
                    and indirect or/and incidental or/and special consequential or/and exemplary damages,
                    whether such interruption or/and suspension or/and termination was justified or not,
                    negligent or intentional, inadvertent or advertent.</li>
                    <li>User agrees that Company shall not be responsible or liable to user, or anyone, for the
                    statements or conduct of any third party of the service. In sum, in no event shall the
                    Company’s total liability to the User for all damages or/and losses or/and causes of action
                    exceed the amount paid by the User to the Company, if any, related to the cause of action.</li>
                </ul>
                <h3 className='py-3'>DISCLAIMER OF CONSEQUENTIAL DAMAGES:</h3>
                <p>In no event shall the Company or any parties, organisations or entities associated with the corporate
                brand name us or otherwise, mentioned at this Website be liable for any damages whatsoever
                (including, without limitations, incidental and consequential damages, lost profits, or damage to
                computer hardware or loss of data information or business interruption) resulting from the use or
                inability to use the Website and the Website material, whether based on warranty, contract, tort, or
                any other legal theory, and whether or not, such organisation or entities were advised of the
                possibility of such damages.</p>

            </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default TermsCondition
