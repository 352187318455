import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import '../src/assets/css/about.css';
import Home from './pages/Home.jsx'
import AboutUs from './pages/AboutUs.jsx';
import Mattress from './pages/Mattress.jsx';
import Pillow from './pages/Pillow.jsx';
import MoreProduct from './pages/MoreProduct.jsx';
import ContactUs from './pages/ContactUs.jsx';
import PillowProductView from './pages/PillowProductView.jsx';
import MattressProductView from './pages/MattressProductView.jsx';
import BabyMattress from './pages/BabyMattress.jsx';
import SofaCumBed from './pages/SofaCumBed.jsx';
import Policy from './pages/Policy.jsx';
import TermsCondition from './pages/TermsCondition.jsx';
import Guarantee from './pages/Guarantee.jsx';
import WarrantyForm from './pages/WarrantyForm.jsx';
import FQ from './pages/F&Q.jsx';
import Comforter from './pages/Comforter.jsx';
import MattressProtectorPreview from './pages/MattressProtectorPreview.jsx';
import MattressToperPreview from './pages/MattressToperPreview.jsx';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/about',
    element: <AboutUs />
  },
  {
    path: '/mattress',
    element: <Mattress />
  },
  {
    path: '/mattress-product/:productId',
    element: <MattressProductView />
  },
  {
    path: '/pillow',
    element: <Pillow />
  },
  {
    path: '/pillow-product/:productId',
    element: <PillowProductView />
  },
  {
    path: '/more-product',
    element: <MoreProduct />
  },
  {
    path: '/baby-mattress',
    element: <BabyMattress />
  },
  {
    path: '/comforter',
    element: <Comforter />
  },
  {
    path: '/mattress-protector',
    element: <MattressProtectorPreview />
  },
  {
    path: '/mattress-toppers',
    element: <MattressToperPreview />
  },
  {
    path: '/sofa-cum-bed',
    element: <SofaCumBed />
  },
  {
    path: '/privacy-policy',
    element: <Policy />
  },
  {
    path: '/terms-and-condition',
    element: <TermsCondition />
  },
  {
    path: '/gurantee',
    element: <Guarantee />
  },
  {
    path: '/ewarranty-registration',
    element: <WarrantyForm />
  },
  {
    path: '/frequently-asked-questions',
    element: <FQ />
  },
  {
    path: '/contact-us',
    element: <ContactUs />
  }
])


function App() {
  return (
    <>
     <RouterProvider router={router} />
    </>
  );
}

export default App;
