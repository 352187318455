import React, {useEffect, useRef} from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import emailjs from '@emailjs/browser';

function WarrantyForm() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_hem01id', 'template_ursegbw', form.current, {
            publicKey: 'bn7rYqutlx-Epr8CI',
          })
          .then(
            () => {
              console.log('SUCCESS!');
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
          );
      };

    useEffect(() => {
        document.title = 'Amani';
        window.scrollTo(0, 0);

    }, [])
  return (
    <>
        <Header />
        <div class="container my-5">
            <h2 className='text-center mb-4'>Warranty Registration Form</h2>
            <div className='mx-auto form-container'>
                <form ref={form} onSubmit={sendEmail}>
                    {/* <!-- Salutation --> */}
                    <div class="form-group">
                        <label for="salutation">Salutation *</label>
                        <select class="form-control" id="salutation" required name='title'>
                            <option value="">Select Salutation</option>
                            <option value="Mr">Mr</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Ms">Ms</option>
                        </select>
                    </div>

                    {/* <!-- Name --> */}
                    <div class="form-group">
                        <label for="name">Name *</label>
                        <input type="text" class="form-control" name='your_name' id="name" placeholder="Enter your name" required />
                    </div>

                    {/* <!-- Address Line 1 --> */}
                    <div class="form-group">
                        <label for="address1">Address Line 1 *</label>
                        <input type="text" class="form-control" name='your_address' id="address1" placeholder="Enter your address" required />
                    </div>

                    {/* <!-- State --> */}
                    <div class="form-group">
                        <label for="state">State *</label>
                        <input type="text" class="form-control" id="address2" name='your_state' placeholder="Enter your state" required />
                    </div>

                    {/* <!-- Pincode --> */}
                    <div class="form-group">
                        <label for="pincode">Pincode *</label>
                        <input type="text" class="form-control" id="pincode" name='pincode' placeholder="Enter your pincode" required />
                    </div>

                    {/* <!-- Cell Phone Number --> */}
                    <div class="form-group">
                        <label for="phone">Cell Phone Number *</label>
                        <input type="tel" class="form-control" id="phone" name='your_number' placeholder="Enter your phone number" required />
                    </div>

                    {/* <!-- Email --> */}
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input type="email" class="form-control" id="email" name='your_mail' placeholder="Enter your email" />
                    </div>

                    {/* <!-- Dealer Name --> */}
                    <div class="form-group">
                        <label for="dealer">Dealer Name *</label>
                        <input type="text" class="form-control" id="dealer" name='dealer_name' placeholder="Enter dealer name" required />
                    </div>

                    {/* <!-- Invoice Number --> */}
                    <div class="form-group">
                        <label for="invoice">Invoice Number *</label>
                        <input type="text" class="form-control" id="invoice" name='invoice_number' placeholder="Enter invoice number" required />
                    </div>

                    {/* <!-- Purchase Date --> */}
                    <div class="form-group">
                        <label for="purchaseDate">Purchase Date *</label>
                        <input type="text" class="form-control" id="purchaseDate" name='purchase_date' placeholder="Enter purchase date" required />
                    </div>

                    {/* <!-- Product Purchased --> */}
                    <div class="form-group">
                        <label for="productPurchased">Product Purchased *</label>
                        <select class="form-control" id="productPurchased" name='your_product' required>
                            <option value="">Select Product Purchased</option>
                            <option value="mattress">Mattress</option>
                            <option value="pillow">Pillow</option>
                        </select>
                    </div>

                    {/* <!-- Dealer Invoice Picture --> */}
                    {/* <div class="form-group">
                        <label for="invoicePicture">Dealer Invoice Picture *</label><br />
                        <input type="file" class="form-control-file mt-2" name='invoice_photo' id="invoicePicture" required />
                    </div> */}

                    {/* <!-- Serial Number --> */}
                    <div class="form-group">
                        <label for="serialNumber">Serial Number *</label>
                        <input type="text" class="form-control" id="serialNumber" name='serial_number' placeholder="Enter serial number" required />
                    </div>

                    {/* <!-- Serial Number Picture --> */}
                    {/* <div class="form-group">
                        <label for="serialPicture">Serial Number Picture *</label><br />
                        <input type="file" class="form-control-file mt-2" name='serial_number_photo' id="serialPicture" required />
                    </div> */}

                    {/* <!-- Submit Button --> */}
                    <button type="submit" value="Send" class="btn-white border-0 fw-semibold px-4">Submit</button>
                </form>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default WarrantyForm
