import React, { useState, useRef, useEffect } from 'react';
import logo from '../assets/img/Logo.png';
import SubHeading from './SubHeading';
import { Link } from 'react-router-dom';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [IsDropDown, setIsDropDown] = useState(false)
  const submenuRef = useRef(null);
  const toggleButtonRef = useRef(null);

  const handleToggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  const toggleDropdown = () => {
    setIsDropDown(!IsDropDown)
  }

  // Function to handle click outside
  const handleClickOutside = (event) => {
    if (submenuRef.current && !submenuRef.current.contains(event.target) &&
        toggleButtonRef.current && !toggleButtonRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  // Add event listener on mount and cleanup on unmount
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav className="navbar nav-bg navbar-expand-lg">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 font-size-1 fw-600 header-nav-item">
              <li className="nav-item">
                <Link className="nav-link active text-white" aria-current="page" to="/">Home</Link>
              </li>
              <li className="nav-item nav-lgs">
                <Link 
                  ref={toggleButtonRef}
                  className="nav-link text-white product-dropdown" 
                  to="#" 
                  onClick={handleToggleMenu}
                >
                  Products &nbsp;
                  <span>
                    <svg className={isMenuOpen ? 'rotate-icon' : ''} width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.632142 1.14221C0.387438 1.38733 0.25 1.71954 0.25 2.0659C0.25 2.41226 0.387438 2.74447 0.632142 2.98959L8.01168 10.3766C8.29666 10.6616 8.68164 10.7891 9.05411 10.7541C9.39159 10.7541 9.72782 10.6279 9.98405 10.3716L17.3673 2.98834C17.4886 2.86713 17.5847 2.72322 17.6503 2.56484C17.7159 2.40646 17.7497 2.23671 17.7497 2.06528C17.7497 1.89385 17.7159 1.7241 17.6503 1.56572C17.5847 1.40734 17.4886 1.26343 17.3673 1.14221C17.2461 1.02099 17.1022 0.924836 16.9438 0.859232C16.7855 0.793629 16.6157 0.759863 16.4443 0.759863C16.2728 0.759863 16.1031 0.793629 15.9447 0.859232C15.7863 0.924836 15.6424 1.02099 15.5212 1.14221L8.99662 7.6668L2.47703 1.14221C2.35595 1.02097 2.21216 0.924789 2.05388 0.859166C1.89559 0.793542 1.72593 0.759766 1.55458 0.759766C1.38324 0.759766 1.21358 0.793542 1.05529 0.859166C0.897014 0.924789 0.753222 1.02097 0.632142 1.14221Z" fill="white"/>
                    </svg>
                  </span>
                </Link>
              </li>
              <li className="nav-item nav-sm dropdown">
                <Link className="nav-link dropdown-toggle text-white" onClick={toggleDropdown}>
                  Products
                </Link>
                <div className={`dropdown-menu ${IsDropDown ? 'show' : ''}`} aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to='/mattress'>Mattress</Link>
                  <Link className="dropdown-item" to='/pillow'>Pillow</Link>
                  <Link className="dropdown-item" to='/more-product'>More Products</Link>
                </div>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" to="/about">About Us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="ms-auto" role="search">
              <Link to="/contact-us" className='btn-yellow text-white fw-600'>Contact</Link>
            </div>
          </div>
        </div>
      </nav>
      {isMenuOpen && (
        <div ref={submenuRef} className="subm">
          <SubHeading />
        </div>
      )}
    </>
  );
}

export default Header;
