import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import mattressData from '../data/mattress';
import pillowData from '../data/pillow';
import sofacumbed from '../assets/img/sofa-cum-bed.png';
import comforter from '../assets/img/comforter.png';
import mattressProtector from '../assets/img/mattress-protector.png';
import mattressToppers from '../assets/img/mattress-toppers.png';

function HomeTabs() {
    const [activeTab, setActiveTab] = useState('mattress');

    const handleTabChange = (tab) => {
        setActiveTab(tab)
    }

    const  getRoute = () => {
        switch (activeTab) {
            case 'mattress':
                return '/mattress';
            case 'pillow':
                return '/pillow';
            case 'more':
                return '/more-product';
            default:
                return '/';
        }
    }
  return (
    <>
      <div className="container text-center">
        <h2 className='text-center discover custum-text-primary fw-600'>Discover Our <span className='custum-font-secondary'>Newest</span> Launches</h2>
        {/* <hr className='hr-purple w-50'/> */}
      </div>
    <div className="container mb-4">
        <ul className="nav nav-pills mb-3 custum-text-primary justify-content-center align-items-center home-tab" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
                <button className={`nav-link fw-bold font-size-1 custum-text-primary ${activeTab === 'mattress' ? 'active' : ''}`} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected={activeTab === 'mattress'} onClick={() => handleTabChange('mattress')}>Mattress</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className={`nav-link fw-bold font-size-1 custum-text-primary ${activeTab === 'pillow' ? 'active' : ''}`} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected={activeTab === 'pillow'} onClick={() => handleTabChange('pillow')}>Pillow</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className={`nav-link fw-bold font-size-1 custum-text-primary ${activeTab === 'more' ? 'active' : ''}`} id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"  aria-selected={activeTab === 'more'} onClick={() => handleTabChange('more')}>More Products</button>
            </li>
        </ul>
    </div>
    {/* tabs container */}
    <div className="tabs-container bg-purple p-4 mb-7">
        <div className="tab-content bg-white py-4 px-5 rounded" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div className="row me-0">
                    {
                        mattressData.find(category => category.category === 'Premium Series')?.products.slice(0,4).map(product => (
                            <div className="col-lg-3 col-md-4 col-sm-12" key={product.id}>
                                <div className="card border-0 matress-card">
                                    <div className="position-relative">
                                        <div className='img-hover'>
                                            <img src={Array.isArray(product.img) ? product.img[0].main : product.img} className="card-img-top" alt="..." />
                                            <div className='position-absolute view-product'>
                                                <span className='me-2'>
                                                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.925 7.65C18.2833 4.09167 14.5833 0.5 10 0.5C5.41667 0.5 1.71667 4.09167 0.075 7.65C0.0255612 7.76006 0 7.87935 0 8C0 8.12065 0.0255612 8.23994 0.075 8.35C1.71667 11.9083 5.41667 15.5 10 15.5C14.5833 15.5 18.2833 11.9083 19.925 8.35C19.9744 8.23994 20 8.12065 20 8C20 7.87935 19.9744 7.76006 19.925 7.65ZM10 12.1667C9.17591 12.1667 8.37033 11.9223 7.68512 11.4645C6.99992 11.0066 6.46587 10.3559 6.1505 9.59451C5.83514 8.83315 5.75262 7.99538 5.91339 7.18712C6.07417 6.37887 6.471 5.63644 7.05372 5.05372C7.63644 4.471 8.37887 4.07417 9.18712 3.91339C9.99538 3.75262 10.8332 3.83514 11.5945 4.1505C12.3559 4.46587 13.0066 4.99992 13.4645 5.68512C13.9223 6.37033 14.1667 7.17591 14.1667 8C14.1653 9.10466 13.7259 10.1637 12.9448 10.9448C12.1637 11.7259 11.1047 12.1653 10 12.1667Z" fill="white"/>
                                                    <path d="M10 10.5C11.3807 10.5 12.5 9.38071 12.5 8C12.5 6.61929 11.3807 5.5 10 5.5C8.61929 5.5 7.5 6.61929 7.5 8C7.5 9.38071 8.61929 10.5 10 10.5Z" fill="white"/>
                                                    </svg>
                                                </span>
                                                <Link to={`/mattress-product/${product.id}`}>View Product</Link>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="pt-2">
                                        <h5 className="font-size-1 fw-600">{product.name}</h5>
                                        <div className='text-center my-4'>
                                            <Link to={`/mattress-product/${product.id}`} className="btn-order font-size-1 fw-600">Order Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div className="row me-0">
                    {
                        pillowData.find(category => category.category === 'Pillow')?.products.slice(0,4).map(product => (
                            <div className="col-lg-3 col-md-4 col-sm-12" key={product.id}>
                                <div className="card border-0 matress-card">
                                    <div className="position-relative">
                                        <div className='img-hover'>
                                            <img src={Array.isArray(product.img) ? product.img[0].main : product.img} className="card-img-top" alt="..." />
                                            <div className='position-absolute view-product'>
                                                <span className='me-2'>
                                                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.925 7.65C18.2833 4.09167 14.5833 0.5 10 0.5C5.41667 0.5 1.71667 4.09167 0.075 7.65C0.0255612 7.76006 0 7.87935 0 8C0 8.12065 0.0255612 8.23994 0.075 8.35C1.71667 11.9083 5.41667 15.5 10 15.5C14.5833 15.5 18.2833 11.9083 19.925 8.35C19.9744 8.23994 20 8.12065 20 8C20 7.87935 19.9744 7.76006 19.925 7.65ZM10 12.1667C9.17591 12.1667 8.37033 11.9223 7.68512 11.4645C6.99992 11.0066 6.46587 10.3559 6.1505 9.59451C5.83514 8.83315 5.75262 7.99538 5.91339 7.18712C6.07417 6.37887 6.471 5.63644 7.05372 5.05372C7.63644 4.471 8.37887 4.07417 9.18712 3.91339C9.99538 3.75262 10.8332 3.83514 11.5945 4.1505C12.3559 4.46587 13.0066 4.99992 13.4645 5.68512C13.9223 6.37033 14.1667 7.17591 14.1667 8C14.1653 9.10466 13.7259 10.1637 12.9448 10.9448C12.1637 11.7259 11.1047 12.1653 10 12.1667Z" fill="white"/>
                                                    <path d="M10 10.5C11.3807 10.5 12.5 9.38071 12.5 8C12.5 6.61929 11.3807 5.5 10 5.5C8.61929 5.5 7.5 6.61929 7.5 8C7.5 9.38071 8.61929 10.5 10 10.5Z" fill="white"/>
                                                    </svg>
                                                </span>
                                                <Link to={`/pillow-product/${product.id}`}>View Product</Link>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="pt-2">
                                        <h5 className="font-size-1 fw-600">{product.name}</h5>
                                        <div className='text-center my-4'>
                                            <Link to={`/pillow-product/${product.id}`} className="btn-order font-size-1 fw-600">Order Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                  
                </div>
            </div>

            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                <div className="row me-0">
                    <div className="col-lg-3 col-md-4 col-sm-12">
                        <div className="card border-0 matress-card">
                            <div className="position-relative">
                                <div className='img-hover'>
                                    <img src={sofacumbed} className="card-img-top moreproductimg" alt="..." />
                                    <div className='position-absolute view-product'>
                                        <span className='me-2'>
                                            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.925 7.65C18.2833 4.09167 14.5833 0.5 10 0.5C5.41667 0.5 1.71667 4.09167 0.075 7.65C0.0255612 7.76006 0 7.87935 0 8C0 8.12065 0.0255612 8.23994 0.075 8.35C1.71667 11.9083 5.41667 15.5 10 15.5C14.5833 15.5 18.2833 11.9083 19.925 8.35C19.9744 8.23994 20 8.12065 20 8C20 7.87935 19.9744 7.76006 19.925 7.65ZM10 12.1667C9.17591 12.1667 8.37033 11.9223 7.68512 11.4645C6.99992 11.0066 6.46587 10.3559 6.1505 9.59451C5.83514 8.83315 5.75262 7.99538 5.91339 7.18712C6.07417 6.37887 6.471 5.63644 7.05372 5.05372C7.63644 4.471 8.37887 4.07417 9.18712 3.91339C9.99538 3.75262 10.8332 3.83514 11.5945 4.1505C12.3559 4.46587 13.0066 4.99992 13.4645 5.68512C13.9223 6.37033 14.1667 7.17591 14.1667 8C14.1653 9.10466 13.7259 10.1637 12.9448 10.9448C12.1637 11.7259 11.1047 12.1653 10 12.1667Z" fill="white"/>
                                            <path d="M10 10.5C11.3807 10.5 12.5 9.38071 12.5 8C12.5 6.61929 11.3807 5.5 10 5.5C8.61929 5.5 7.5 6.61929 7.5 8C7.5 9.38071 8.61929 10.5 10 10.5Z" fill="white"/>
                                            </svg>
                                        </span>
                                        <Link to='/sofa-cum-bed'>View Product</Link>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="pt-2">
                                <h5 className="font-size-1 fw-600">Sofa Cum Bed</h5>
                                <div className='text-center my-4'>
                                    <Link to='/sofa-cum-bed' className="btn-order font-size-1 fw-600">Order Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-12">
                        <div className="card border-0 matress-card">
                            <div className="position-relative">
                                <div className='img-hover'>
                                    <img src={comforter} className="card-img-top moreproductimg" alt="..." />
                                    <div className='position-absolute view-product'>
                                        <span className='me-2'>
                                            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.925 7.65C18.2833 4.09167 14.5833 0.5 10 0.5C5.41667 0.5 1.71667 4.09167 0.075 7.65C0.0255612 7.76006 0 7.87935 0 8C0 8.12065 0.0255612 8.23994 0.075 8.35C1.71667 11.9083 5.41667 15.5 10 15.5C14.5833 15.5 18.2833 11.9083 19.925 8.35C19.9744 8.23994 20 8.12065 20 8C20 7.87935 19.9744 7.76006 19.925 7.65ZM10 12.1667C9.17591 12.1667 8.37033 11.9223 7.68512 11.4645C6.99992 11.0066 6.46587 10.3559 6.1505 9.59451C5.83514 8.83315 5.75262 7.99538 5.91339 7.18712C6.07417 6.37887 6.471 5.63644 7.05372 5.05372C7.63644 4.471 8.37887 4.07417 9.18712 3.91339C9.99538 3.75262 10.8332 3.83514 11.5945 4.1505C12.3559 4.46587 13.0066 4.99992 13.4645 5.68512C13.9223 6.37033 14.1667 7.17591 14.1667 8C14.1653 9.10466 13.7259 10.1637 12.9448 10.9448C12.1637 11.7259 11.1047 12.1653 10 12.1667Z" fill="white"/>
                                            <path d="M10 10.5C11.3807 10.5 12.5 9.38071 12.5 8C12.5 6.61929 11.3807 5.5 10 5.5C8.61929 5.5 7.5 6.61929 7.5 8C7.5 9.38071 8.61929 10.5 10 10.5Z" fill="white"/>
                                            </svg>
                                        </span>
                                        <Link to='/comforter'>View Product</Link>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="pt-2">
                                <h5 className="font-size-1 fw-600">Comforter</h5>
                                <div className='text-center my-4'>
                                    <Link to='/comforter' className="btn-order font-size-1 fw-600">Order Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-12">
                        <div className="card border-0 matress-card">
                            <div className="position-relative">
                                <div className='img-hover'>
                                    <img src={mattressProtector} className="card-img-top moreproductimg" alt="..." />
                                    <div className='position-absolute view-product'>
                                        <span className='me-2'>
                                            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.925 7.65C18.2833 4.09167 14.5833 0.5 10 0.5C5.41667 0.5 1.71667 4.09167 0.075 7.65C0.0255612 7.76006 0 7.87935 0 8C0 8.12065 0.0255612 8.23994 0.075 8.35C1.71667 11.9083 5.41667 15.5 10 15.5C14.5833 15.5 18.2833 11.9083 19.925 8.35C19.9744 8.23994 20 8.12065 20 8C20 7.87935 19.9744 7.76006 19.925 7.65ZM10 12.1667C9.17591 12.1667 8.37033 11.9223 7.68512 11.4645C6.99992 11.0066 6.46587 10.3559 6.1505 9.59451C5.83514 8.83315 5.75262 7.99538 5.91339 7.18712C6.07417 6.37887 6.471 5.63644 7.05372 5.05372C7.63644 4.471 8.37887 4.07417 9.18712 3.91339C9.99538 3.75262 10.8332 3.83514 11.5945 4.1505C12.3559 4.46587 13.0066 4.99992 13.4645 5.68512C13.9223 6.37033 14.1667 7.17591 14.1667 8C14.1653 9.10466 13.7259 10.1637 12.9448 10.9448C12.1637 11.7259 11.1047 12.1653 10 12.1667Z" fill="white"/>
                                            <path d="M10 10.5C11.3807 10.5 12.5 9.38071 12.5 8C12.5 6.61929 11.3807 5.5 10 5.5C8.61929 5.5 7.5 6.61929 7.5 8C7.5 9.38071 8.61929 10.5 10 10.5Z" fill="white"/>
                                            </svg>
                                        </span>
                                        <Link to='/mattress-protector'>View Product</Link>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="pt-2">
                                <h5 className="font-size-1 fw-600">Mattress Protector</h5>
                                <div className='text-center my-4'>
                                    <Link to='/mattress-protector' className="btn-order font-size-1 fw-600">Order Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-12">
                        <div className="card border-0 matress-card">
                            <div className="position-relative">
                                <div className='img-hover'>
                                    <img src={mattressToppers} className="card-img-top moreproductimg" alt="..." />
                                    <div className='position-absolute view-product'>
                                        <span className='me-2'>
                                            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.925 7.65C18.2833 4.09167 14.5833 0.5 10 0.5C5.41667 0.5 1.71667 4.09167 0.075 7.65C0.0255612 7.76006 0 7.87935 0 8C0 8.12065 0.0255612 8.23994 0.075 8.35C1.71667 11.9083 5.41667 15.5 10 15.5C14.5833 15.5 18.2833 11.9083 19.925 8.35C19.9744 8.23994 20 8.12065 20 8C20 7.87935 19.9744 7.76006 19.925 7.65ZM10 12.1667C9.17591 12.1667 8.37033 11.9223 7.68512 11.4645C6.99992 11.0066 6.46587 10.3559 6.1505 9.59451C5.83514 8.83315 5.75262 7.99538 5.91339 7.18712C6.07417 6.37887 6.471 5.63644 7.05372 5.05372C7.63644 4.471 8.37887 4.07417 9.18712 3.91339C9.99538 3.75262 10.8332 3.83514 11.5945 4.1505C12.3559 4.46587 13.0066 4.99992 13.4645 5.68512C13.9223 6.37033 14.1667 7.17591 14.1667 8C14.1653 9.10466 13.7259 10.1637 12.9448 10.9448C12.1637 11.7259 11.1047 12.1653 10 12.1667Z" fill="white"/>
                                            <path d="M10 10.5C11.3807 10.5 12.5 9.38071 12.5 8C12.5 6.61929 11.3807 5.5 10 5.5C8.61929 5.5 7.5 6.61929 7.5 8C7.5 9.38071 8.61929 10.5 10 10.5Z" fill="white"/>
                                            </svg>
                                        </span>
                                        <Link to='/mattress-toppers'>View Product</Link>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="pt-2">
                                <h5 className="font-size-1 fw-600">Mattress Toppers</h5>
                                <div className='text-center my-4'>
                                    <Link to='/mattress-toppers' className="btn-order font-size-1 fw-600">Order Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* button */}
        <div className="text-center mt-4">
            <Link to={getRoute()} className='btn-white fw-600 text-black'>See More &nbsp;
                <span>
                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.6223 14.0906C7.46269 16.9917 7.00918 21.2676 6.81401 22.0323C6.60161 21.2676 6.17681 16.9917 0 14.0906C1.73947 13.4667 3.28392 12.3974 4.4784 10.9899C5.67289 9.58245 6.47539 7.88626 6.8054 6.07156C6.80782 6.0651 6.80782 6.05798 6.8054 6.05151C6.80679 6.05812 6.80679 6.06495 6.8054 6.07156C7.13863 7.88668 7.94366 9.58268 9.13995 10.9899C10.3363 12.3972 11.8819 13.4664 13.6223 14.0906Z" fill="#171717"/>
                        <path d="M22.4999 10.104C18.6566 11.9082 18.3868 14.566 18.2634 15.0414C18.1371 14.566 17.8673 11.9082 14.0269 10.104C15.1089 9.71569 16.0697 9.05037 16.8127 8.17473C17.5558 7.29909 18.0551 6.24391 18.2605 5.11499C18.4686 6.24368 18.9696 7.29824 19.7134 8.17359C20.4572 9.04894 21.418 9.7145 22.4999 10.104Z" fill="#171717"/>
                        <path d="M15.0088 3.0415C12.6724 4.13838 12.5059 5.7565 12.4255 6.04576C12.3509 5.75937 12.1844 4.13838 9.84229 3.0415C10.5037 2.80681 11.0912 2.40192 11.5453 1.86781C11.9995 1.33371 12.3041 0.689358 12.4284 0C12.5533 0.688277 12.8575 1.33165 13.3104 1.86552C13.7633 2.39939 14.349 2.80496 15.0088 3.0415Z" fill="#171717"/>
                    </svg>
                </span>
            </Link>
        </div>
    </div>
        
    </>
  )
}

export default HomeTabs
