import React, {useEffect} from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

function Policy() {
  useEffect(() => {
    document.title = 'Amani';
    window.scrollTo(0, 0);

  }, [])
  return (
    <>
      <Header />
      <div className="container my-5">
         <div className="row">
             <div className="col-12">
                <h3 className='text-center fw-bold text-decoration-underline text-decoration-offset-1'>PRIVACY AND DATA PROTECTION POLICY</h3>
                <p className='pt-3'>This privacy policy sets out how AMANI uses and protects any informa􀆟on you give AMANI when you
                use this website. Here we are commi􀆩ed to ensuring that your privacy is protected at an utmost
                level. Should you provide your personal and certain informa􀆟on by which you can be iden􀆟fied when
                using this website, then you can be assured that it will only be used as per this privacy statement.
                AMANI may change this policy from 􀆟me to 􀆟me by upda􀆟ng this page whenever it is required. You
                are requested to check this page from 􀆟me to 􀆟me to ensure that you are happy with any changes.</p>
                <h3>What we take note off</h3>
                <p>We may collect the following information:</p>
                <ul className='ul-list li'>
                    <li>Name</li>
                    <li>Contact informa􀆟on, including email address</li>
                    <li>Demographic informa􀆟on such as postal code, preferences and interests</li>
                    <li>Other informa􀆟on relevant to customer surveys and / or regarding offers & schemes</li>
                </ul>
                <p>For the exhaus􀆟ve list of cookies we collect, see the List of cookies we collect sec􀆟on.</p>
                <p>What do we do with the informa􀆟on we gather from your link.</p>
                <p>We require this information to understand your needs and provide you with a be􀆩er service, in
                particular for the following reasons:</p>
                <ul className='ul-list li'>
                    <li>We may periodically send promo􀆟onal emails about new products, special offers or other
                    informa􀆟on that you may find interes􀆟ng using the email address you provided. Internal
                    record keeping for any future requirements</li>
                    <li>May use this informa􀆟on to improve our services and products por􀆞olios.</li>
                    <li>Occasionally, we also use your informa􀆟on to contact you for market research purposes. We
                    may contact you by email, phone, fax or mail. We may use the informa􀆟on to customise the
                    website according to your interests.</li>
                </ul>
                <h3 className='py-3'>SECURITY</h3>
                <p>We are ensuring that your informa􀆟on is fully secure. To prevent unauthorised access or disclosure,
                we have established suitable electronic so􀅌ware and procedures to guard the informa􀆟on we collect
                online.</p>
                <h5 className='py-3'>How we use cookies</h5>
                <p>A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you
                agree, the file is added, and the cookie helps analyse web traffic or lets you know when you visit a
                par􀆟cular site. Cookies allow web applica􀆟ons to respond to you as an individual. The web
                applica􀆟on can tailor its opera􀆟ons to your needs, likes and dislikes by gathering and remembering
                your preferences.</p>
                <p>We use traffic log cookies to iden􀆟fy which pages are being used. This helps us analyse data about
                web page traffic and improve our website to tailor it to customer needs. We only use this
                information for statistical analysis, which removes the data from the system.</p>
                <p>Overall, cookies help us provide a be􀆩er website by enabling us to monitor which pages you find
                useful and which you do not. A cookie in no way gives us access to your computer or any informa􀆟on
                about you other than the data you choose to share with us. You can choose to accept or decline
                cookies. Most web browsers automa􀆟cally accept cookies, but you can modify your browser se􀆫ng
                to decline cookies. This may prevent you from taking full advantage of the website.</p>
                <h5 className='py-3'>Links to other websites</h5>
                <p>Our website may contain links to other websites of interest. However, once you have used these links
                to leave our site, you should note that we do not have any control over that other website.
                Therefore, we cannot be responsible for the protec􀆟on and privacy of any informa􀆟on you provide
                whilst visi􀆟ng such sites, which are not governed by this privacy statement. You should exercise
                cau􀆟on and look at the privacy statement applicable to the website in ques􀆟on.</p>
                <h5 className='py-3'>Controlling your personal informa􀆟on</h5>
                <p>You may choose to restrict the collec􀆟on or use of your personal informa􀆟on in the following ways:</p>
                <ul className='ul-list li'>
                    <li>Whenever you are asked to fill in a form on the website, look for the box you can click to
                    indicate that you want to avoid the informa􀆟on used by anybody for direct marke􀆟ng
                    purposes.</li>
                    <li>If you have previously agreed to us using your personal informa􀆟on for direct marke􀆟ng
                    purposes, you may change your mind at any 􀆟me by wri􀆟ng to or emailing us at
                    onlineorders@amanima􀆩ress.com</li>
                </ul>
                <p>We will not sell, distribute or lease your personal informa􀆟on to third par􀆟es unless we have your
                permission or are legally required. We may use your personal informa􀆟on to send you promo􀆟onal
                informa􀆟on about third par􀆟es which you may find interes􀆟ng if you tell us that you wish this to
                happen.</p>
                <p>You may request details of personal informa􀆟on we hold about you under the Data Protec􀆟on Act
                1998. A small fee will be payable. If you would like a copy of the informa􀆟on contained to you, please
                write to us.</p>
                <p>If you believe any informa􀆟on, we are holding on to your needs to be corrected or completed, please
                email us as soon as possible at the above address. We will promptly correct any informa􀆟on found to
                be incorrect.</p>

             </div>
         </div>
      </div>
      <Footer />
    </>
  )
}

export default Policy
