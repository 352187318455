import React, {useEffect} from 'react';
import confortable from '../assets/img/confortable.png';
import durable from '../assets/img/durable.png';
import firmness from '../assets/img/firmness.png';
import posture from '../assets/img/posture.png';
import nonAllerge from '../assets/img/non-allerge.png';
import Header from '../components/Header';
import Footer from '../components/Footer';

function AboutUs() {
    useEffect( () => {
        document.title = 'About - Amani';
        window.scrollTo(0, 0);
    }, [])
  return (
    <>
    <Header />
    <div className="about-poster"></div>
      <div className="container mt-5 mb-7">
        <div className="row">
            <div className="col-12">
                <h2 className='text-center fw-600 custum-text-primary'>About Us</h2>
                <hr className='hr-yellow'/>
                <p className='font-size-1'>AMANI is a renowned company in multiple product segments for half a decade. AMANI Furniture Private Limited started in 2018, venturing into Plastic Extrusion, founded by Mr. Naresh Agarwal and Mr. Deep Poddar. Since then, the company has diversified into Expanded Poly Ethylene (EPE) sheets and mattresses.</p>
                <p className='font-size-1'>The profound vision and strong conviction of Mr. Deep Poddar have led the group to great heights, making it a trusted brand. With the induction of a new Polyurethane Foam Plant, AMANI has added extra value to its in-house mattress manufacturing, producing excellent quality mattresses of all types at their facilities.</p>
                <p className='font-size-1'>AMANI Mattress, a brand of AMANI Furniture Pvt Ltd, places great emphasis on the importance of healthy sleeping in your life.
                </p>
            </div>
        </div>
        <div className="row mt-7">
            <div className="col-12">
                <h2 className='text-center fw-600 custum-text-primary'><span className='custum-font-secondary '>Why</span> AMANI</h2>
                <hr className='hr-yellow'/>
                <p className='font-size-1'>Your restfulness matters to us. That’s why AMANI Mattress is here to enhance your sleep experience. AMANI articulates and follows science and advanced technology to create a better sleeping surface, ensuring you get a good night's sleep. The importance of getting enough sleep remains our prime focus, irrespective of the price band set for the products.</p>
            </div>
        </div>
        {/* Comfortable */}
        <div className="amani-container mt-5">
            <div className="amani-text-container">
                <div className="amani-title custum-text-primary font-size-2">
                Profoundly <span className="amani-highlight custum-font-secondary">Comfortable</span>
                </div>
                <div className="amani-description">
                AMANI mattress molds to your body contours, ensuring undisturbed sleep
                every night by continuously adjusting to maintain perfect posture.
                </div>
            </div>
            <div className="amani-image-container">
                <img
                src={confortable}
                alt="Sleeping person illustration"
                className="amani-image"
                />
            </div>
        </div>
        {/* Highly Durable */}
        <div className="amani-container mt-4">
            <div className="amani-image-container order-smd-2">
                <img
                src={durable}
                alt="Highly Durable illustration"
                className="amani-image"
                />
            </div>
            <div className="amani-text-container">
                <div className="amani-title custum-text-primary font-size-2">
                Highly <span className="amani-highlight custum-font-secondary">Durable</span>
                </div>
                <div className="amani-description">
                AMANI mattress retains its shape and size for many years, providing consistent comfort night after night.
                </div>
            </div>
           
        </div>
        {/* Posture Support */}
        <div className="amani-container mt-4">
            <div className="amani-text-container">
                <div className="amani-title custum-text-primary font-size-2">
                Posture <span className="amani-highlight custum-font-secondary">Support</span>
                </div>
                <div className="amani-description">
                AMANI mattress supports the natural curve of your spine, reducing pressure and improving sleep, so you wake up recharged.
                </div>
            </div>
            <div className="amani-image-container">
                <img
                src={posture}
                alt="Highly Durable illustration"
                className="amani-image"
                />
            </div>
        </div>
        {/* Non-allergenic & Well Ventilated */}
        <div className="amani-container mt-4">
            <div className="amani-image-container order-smd-2">
                <img
                src={nonAllerge}
                alt="Highly Durable illustration"
                className="amani-image"
                />
            </div>
            <div className="amani-text-container">
                <div className="amani-title custum-text-primary font-size-2">
                Non- <span className="custum-font-secondary">allergenic</span> & Well <span className="custum-font-secondary">Ventilated</span>
                </div>
                <div className="amani-description">
                AMANI mattress repels dust mites, bacteria, and fungi, preventing allergies. Its ventilated interior keeps the mattress fresh and dry.
                </div>
            </div>
        </div>
        {/* Multi-Layered Dual Firmness */}
        <div className="amani-container mt-4">
            <div className="amani-text-container">
                <div className="amani-title custum-text-primary font-size-2">
                Multi- <span className="custum-font-secondary">Layered</span> Dual <span className="custum-font-secondary">Firmness</span>
                </div>
                <div className="amani-description">
                AMANImattress features a firm side and a soft side for personalized comfort, adjusting to different body weights and frames.
                </div>
            </div>
            <div className="amani-image-container">
                <img
                src={firmness}
                alt="Highly Durable illustration"
                className="amani-image"
                />
            </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AboutUs
