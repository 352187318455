import React, { useState } from 'react';
import '../assets/css/header.css';
import Head1 from '../assets/img/head1.png';
import Subimg1 from '../assets/img/sub-img-1.png';
import Subimg2 from '../assets/img/sub-img-2.png';
import { Link } from 'react-router-dom';

function SubHeading() {
  const [isHovered, setIsHovered] = useState(false);
  const [isPillowHovered, setPillowIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handlePillowMouseEnter = () => setPillowIsHovered(true);
  const handlePillowMouseLeave = () => setPillowIsHovered(false);

  return (
    <>
      <div className="container-fluid p-3 sub-headings">
        <div className="row">
          {/* Left Side (2 Cards) */}
          <div className="col-md-4">
            <div className="row">
              {/* First Card */}
              <div className="col-md-10">
                <div className="mb-3 product-subimg-1">
                  {isHovered ? (
                    <img src={Subimg1} className="card-img-top" alt="Build Your Own Bundle" />
                  ): (
                    <img src={Head1} className="card-img-top" alt="Build Your Own Bundle" />
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Middle Side (Links) */}
          <div className="col-md-4 text-right d-flex">
            <div>
              <ul className="list-unstyled font-size-1">
                <li className="py-3 sub-lists" 
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}>
                  <div className='d-flex justify-content-between'>
                    <Link className="d-block fw-600 mattress-subnav"
                    >
                      Mattress 
                    </Link>
                    <span className='sub-arrow'>
                        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.75 0.5V15.5L12 8L0.75 0.5Z" fill="#FFC608"/>
                        </svg>
                    </span>
                  </div>
                </li>
                <li className="py-3 sub-lists" 
                  onMouseEnter={handlePillowMouseEnter}
                  onMouseLeave={handlePillowMouseLeave}>
                  <div className='d-flex justify-content-between'>
                    <Link to="/pillow" className="d-block fw-600">
                      Pillow
                    </Link>
                    <span className='sub-arrow'>
                        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.75 0.5V15.5L12 8L0.75 0.5Z" fill="#FFC608"/>
                        </svg>
                    </span>
                  </div>
                </li>
                <li className="py-3 sub-lists-a">
                  <Link to="/more-product" className="d-block fw-600">More Products</Link>
                </li>
              </ul>
            </div>
            <div className="ms-5">
              {/* Mattress dropdown */}
              <ul
                className={`hover-menu list-unstyled px-4 font-size-1 rounded ${isHovered ? 'show' : ''}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <li className="py-2">
                  <Link to="/mattress" className="d-block mb-2">Standard Series</Link>
                </li>
                <li className="py-2">
                  <Link to="/mattress" className="d-block mb-2">Amani Fitline</Link>
                </li>
                <li className="py-2">
                  <Link to="/mattress" className="d-block mb-2">Comfort Line</Link>
                </li>
                <li className="py-2">
                  <Link to="/mattress" className="d-block mb-2">Spring Series</Link>
                </li>
                <li className="py-2">
                  <Link to="/mattress" className="d-block mb-2">Premium Series</Link>
                </li>
                <li className="py-2">
                  <Link to="/mattress" className="d-block mb-2">Luxury Line</Link>
                </li>
                <li className="py-2">
                  <Link to="/mattress" className="d-block mb-2">Signature Line</Link>
                </li>
              </ul>
              {/* Pillow dropdown */}
              <ul
                className={`hover-menu list-unstyled px-4 font-size-1 rounded ${isPillowHovered ? 'show' : ''}`}
                onMouseEnter={handlePillowMouseEnter}
                onMouseLeave={handlePillowMouseLeave}
              >
                <li className="py-2">
                  <Link to="/pillow" className="d-block mb-2">Luxury Line</Link>
                </li>
                <li className="py-2">
                  <Link to="/pillow" className="d-block mb-2">Doctor Pillow</Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Right Side (Single Card) */}
          <div className="col-md-4 text-center">
            {isHovered && (
              <img src={Head1} alt="Aman" className="img-fluid right-side-img" />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            {isHovered && (
              <img src={Subimg2} alt="" className="subimg-2" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SubHeading;
