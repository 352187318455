import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

function FQ() {
  return (
    <>
      <Header />
        <div className="container mt-5 mb-7">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2>FAQ’s </h2>
                </div>
            </div>
            <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        1.	What products does Amani offer?
                    </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Amani specializes in high-quality mattresses, pillows, mattress comforters, mattress toppers, baby mattresses, and sofa cum beds, all designed for ultimate comfort and reliability.
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        2.	What makes Amani mattresses different from others?
                    </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Amani mattresses are crafted with advanced technology, including memory foam, natural latex, and pocket spring core, ensuring body contouring and unmatched comfort for a restful sleep.
                    </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        3.	Does Amani offer a warranty on its products?
                    </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Yes, all Amani mattresses and other sleep products have a comprehensive warranty, ensuring you receive only the highest quality for long-term use.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        4.	Where does Amani deliver its products?
                    </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Amani currently delivers mattresses and other products only to locations within Eastern India. This includes states like West Bengal, Bihar, Odisha, Assam, and nearby regions.
                    </div>
                    </div>
                </div>
                
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSix">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        5.	Can I order an Amani mattress if I’m outside Eastern India?
                    </button>
                    </h2>
                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        At the moment, Amani’s delivery service is limited to Eastern India. We are working on expanding our delivery network, so stay tuned for future updates!
                    </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSeven">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        6.	Are there any delivery charges for locations within Eastern India?
                    </button>
                    </h2>
                    <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        Delivery charges may vary depending on your exact location within Eastern India. Please contact our customer service team or check the delivery options during checkout for more details.
                    </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingEight">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                        7.	 How can I contact Amani for any inquiries or support?
                    </button>
                    </h2>
                    <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        You can contact Amani at 011-41133999. 
                    </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingNine">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                        8.	Can I track my order in India?
                    </button>
                    </h2>
                    <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                    Yes, once your order is dispatched, we will provide you with tracking details so you can monitor your delivery. 
                    </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTen">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                    9.	How long does delivery take?
                    </button>
                    </h2>
                    <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      Delivery usually takes 5–7 business days for locations within Eastern India. You will be notified of any delays or updates regarding your order.
                    </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingEleven">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                        10.	How do I care for my Amani products?
                    </button>
                    </h2>
                    <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      We provide care instructions with each product. General maintenance includes rotating mattresses, using protective covers, and following cleaning instructions to ensure long-lasting comfort and durability.
                    </div>
                    </div>
                </div>
            </div>
            <div className="mattress-f my-4">
                <h4>QUERIES FOR MATTRESS</h4>
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading1">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                         1.	What types of mattresses does Amani offer?
                        </button>
                        </h2>
                        <div id="collapse1" class="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Amani offers a variety of mattresses including memory foam, natural latex, and pocket spring mattresses, each designed for comfort and support.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading2">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                            2.	How do I choose the right mattress?
                        </button>
                        </h2>
                        <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Choosing the right mattress depends on your sleeping style. Memory foam provides pressure relief, latex offers a natural feel, and spring mattresses are ideal for firmer support.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading3">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                            3.	How long does an Amani mattress last?
                        </button>
                        </h2>
                        <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            With proper care, Amani mattresses can last 7–10 years. Regular rotation and using a mattress protector can extend its life.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading4">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                            4.  Can I customize the size of my mattress?
                        </button>
                        </h2>
                        <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        Yes, Amani offers customization options for mattress sizes. Contact our customer service for further details.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading5">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                            4.  Can I customize the size of my mattress?
                        </button>
                        </h2>
                        <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        Yes, Amani offers customization options for mattress sizes. Contact our customer service for further details.
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="PILLOWS-f my-4">
                <h4>QUERIES FOR PILLOWS</h4>
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading11">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                            1.	What types of pillows are available at Amani?
                        </button>
                        </h2>
                        <div id="collapse11" class="accordion-collapse collapse" aria-labelledby="heading11" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Amani offers a range of pillows, including memory foam, latex, and down-alternative pillows, designed for maximum comfort and neck support.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading12">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                        2.	How do I select the right pillow?
                        </button>
                        </h2>
                        <div id="collapse12" class="accordion-collapse collapse" aria-labelledby="heading12" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        If you are a side sleeper, a firm pillow is ideal. Back sleepers may prefer medium-firm, while stomach sleepers should opt for softer pillows to maintain neck alignment.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading13">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                            3. How long do Amani pillows last?
                        </button>
                        </h2>
                        <div id="collapse13" class="accordion-collapse collapse" aria-labelledby="heading13" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                             Amani pillows typically last 1–2 years, depending on usage. Regular fluffing and using pillow protectors can help extend their life.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading15">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
                            4.  Are Amani pillows hypoallergenic?
                        </button>
                        </h2>
                        <div id="collapse15" class="accordion-collapse collapse" aria-labelledby="heading15" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        Yes, many of our pillows are made from hypoallergenic materials, making them suitable for allergy-prone individuals.
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="toppers-f my-4">
                <h4>QUERIES FOR Mattress Toppers </h4>
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading16">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
                            1. What is the purpose of a mattress topper?
                        </button>
                        </h2>
                        <div id="collapse16" class="accordion-collapse collapse" aria-labelledby="heading16" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            A mattress topper adds an extra layer of comfort and support to your existing mattress, helping to extend its life and improve your sleep experience.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading17">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
                        2. What types of mattress toppers does Amani offer?
                        </button>
                        </h2>
                        <div id="collapse17" class="accordion-collapse collapse" aria-labelledby="heading17" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        Amani offers mattress toppers in memory foam and latex options, designed to enhance comfort and contour to your body’s shape.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading18">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse18" aria-expanded="false" aria-controls="collapse18">
                        3. How do I care for my mattress topper?
                        </button>
                        </h2>
                        <div id="collapse18" class="accordion-collapse collapse" aria-labelledby="heading18" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        To maintain your mattress topper, rotate it periodically and use a cover to protect it from spills and stains. Cleaning instructions will vary depending on the material.
                        </div>
                        </div>
                    </div>
                
                </div>
            </div>

            <div className="comfortor-f my-4">
                <h4>QUERIES FOR COMFORTER</h4>
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading19">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse19" aria-expanded="false" aria-controls="collapse19">
                        1. What types of comforters does Amani provide?
                        </button>
                        </h2>
                        <div id="collapse19" class="accordion-collapse collapse" aria-labelledby="heading19" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        Amani offers comforters made from soft, breathable fabrics with various fillings such as microfiber and down-alternatives, ensuring warmth and comfort all year round.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading20">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse20" aria-expanded="false" aria-controls="collapse20">
                        2. How should I care for my Amani comforter?
                        </button>
                        </h2>
                        <div id="collapse20" class="accordion-collapse collapse" aria-labelledby="heading20" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        Most Amani comforters are machine-washable, but always refer to the care label for specific instructions. Air dry to maintain its softness and shape.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading21">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22">
                        3. Are Amani comforters suitable for all seasons?
                        </button>
                        </h2>
                        <div id="collapse22" class="accordion-collapse collapse" aria-labelledby="heading21" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        Yes, Amani offers both lightweight and heavier comforters, making them suitable for different seasons and preferences.
                        </div>
                        </div>
                    </div>
                
                </div>
            </div>

            <div className="comfortor-f my-4">
                <h4>QUERIES FOR Sofa Cum Beds</h4>
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading23">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse23" aria-expanded="false" aria-controls="collapse23">
                        1. What are the benefits of an Amani sofa cum bed?
                        </button>
                        </h2>
                        <div id="collapse23" class="accordion-collapse collapse" aria-labelledby="heading23" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        Amani’s sofa cum beds offer space-saving solutions, easily transforming from a stylish sofa into a comfortable bed, perfect for homes with limited space.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading24">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse24" aria-expanded="false" aria-controls="collapse24">
                        2. How durable are the sofa cum beds?
                        </button>
                        </h2>
                        <div id="collapse24" class="accordion-collapse collapse" aria-labelledby="heading24" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        Amani sofa cum beds are made with high-quality materials to ensure durability and long-lasting comfort, suitable for both regular seating and sleeping.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading25">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse25" aria-expanded="false" aria-controls="collapse25">
                        3. Are there customization options for sofas cum beds?
                        </button>
                        </h2>
                        <div id="collapse25" class="accordion-collapse collapse" aria-labelledby="heading25" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        Yes, Amani offers customization for sofas cum beds in terms of fabric and size. Contact customer service for more information.
                        </div>
                        </div>
                    </div>
                
                </div>
            </div>

            <div className="comfortor-f my-4">
                <h4>QUERIES FOR Baby Mattresses</h4>
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading26">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse26" aria-expanded="false" aria-controls="collapse26">
                        1. What makes Amani baby mattresses special?
                        </button>
                        </h2>
                        <div id="collapse26" class="accordion-collapse collapse" aria-labelledby="heading26" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        Amani baby mattresses are designed with safety and comfort in mind, using hypoallergenic materials and breathable fabrics to ensure a good night’s sleep for your little one.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading27">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse27" aria-expanded="false" aria-controls="collapse27">
                        2. How do I choose the right size for my baby’s mattress?
                        </button>
                        </h2>
                        <div id="collapse27" class="accordion-collapse collapse" aria-labelledby="heading27" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        Amani offers a variety of standard baby mattress sizes, suitable for cribs and bassinets. Please check your crib's dimensions before making a purchase.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading28">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse28" aria-expanded="false" aria-controls="collapse28">
                        3. Are Amani baby mattresses safe for newborns?
                        </button>
                        </h2>
                        <div id="collapse28" class="accordion-collapse collapse" aria-labelledby="heading28" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        Yes, all Amani baby mattresses meet safety standards and are made with materials that ensure breathability and comfort for newborns.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading29">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse29" aria-expanded="false" aria-controls="collapse29">
                        4. How do I clean my baby’s mattress?
                        </button>
                        </h2>
                        <div id="collapse29" class="accordion-collapse collapse" aria-labelledby="heading29" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        Amani baby mattresses come with removable covers that are machine-washable for easy maintenance. Always follow the care instructions for best results.
                        </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
      <Footer />
    </>
  )
}

export default FQ
