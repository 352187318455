import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import babyMattress from '../assets/img/baby-mattress.png';
import mattressProtector from '../assets/img/mattress-protector.png';
import comforter from '../assets/img/comforter.png';
import mattressToppers from '../assets/img/mattress-toppers.png';
import sofaCumBed from '../assets/img/sofa-cum-bed.png';
import Header from '../components/Header';
import Footer from '../components/Footer';

function MoreProduct() {
   useEffect( () => {
      document.title = 'Products - Amani';
      window.scrollTo(0, 0);
   })
  return (
    <>
      <Header />
       <div className="container">
          <div className="d-flex font-size-2 fw-600 gap-3 justify-content-center mt-5">
             <p className='mb-0'>Other</p>
             <p className='custum-font-secondary mb-0'>Products</p>
          </div>
          <hr className='hr-yellow'/>
          <div className="more-products-deatils mt-7">

             <div className="row">
                <div className="col-md-6 col-sm-6">
                    <div className="product-image">
                        <img src={babyMattress} alt="product"/>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 d-flex flex-column justify-content-between mt-mb-2">
                    <div className="product-text">
                        <h2 className='text-center custum-text-primary fw-600'>Baby Mattress</h2>
                        <p className='font-size-1 pt-3'>Amani Baby Mattress is crafted with your child’s safety and comfort in mind. Hypoallergenic and gentle on sensitive skin, they provide optimal support for your baby's developing body, ensuring a peaceful sleep.
                        </p>
                    </div>
                    <div className="button text-center">
                      <Link to='/baby-mattress' className="btn-purple text-white">View More</Link>
                    </div>
                </div>
             </div>
              {/* comforter */}
             <div className="row my-7">
                <div className="col-md-6 col-sm-6 d-flex flex-column justify-content-between order-smd-2 mt-mb-2">
                    <div className="product-text">
                        <h2 className='text-center custum-text-primary fw-600'>Comforter</h2>
                        <p className='font-size-1 pt-3'>Amani Comforters are carefully filled with microfiber, making them lightweight yet warm, perfect for cozy nights. The breathable fabric ensures comfort throughout the year, and the elegant design adds a touch of luxury to your bedroom.
                        </p>
                    </div>
                    <div className="button text-center">
                      <Link to='/comforter' className="btn-purple text-white">View More</Link>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6">
                    <div className="product-image text-center">
                        <img src={comforter} alt="product"/>
                    </div>
                </div>
             </div>
             {/* Mattress Protector */}
             <div className="row my-7">
               <div className="col-md-6 col-sm-6">
                  <div className="product-image">
                     <img src={mattressProtector} alt="product"/>
                  </div>
               </div>

               <div className="col-md-6 col-sm-6 d-flex flex-column justify-content-between mt-mb-2">
                  <div className="product-text">
                     <h2 className='text-center custum-text-primary fw-600'>Mattress Protector</h2>
                     <p className='font-size-1 pt-3'>Amani Mattress Protector offers high-quality protectors that extend the life of your mattress. Waterproof and anti-bacterial, they shield your mattress from liquids and allergens, maintaining a clean and healthy sleeping environment.</p>
                  </div>
                  <div className="button text-center">
                     <Link to='/mattress-protector' className="btn-purple text-white">View More</Link>
                  </div>
               </div>
                
             </div>
             {/* Mattress Toppers */}
             <div className="row my-7">
                <div className="col-md-6 col-sm-6 d-flex flex-column justify-content-between order-smd-1 mt-mb-2">
                    <div className="product-text">
                        <h2 className='text-center custum-text-primary fw-600'>Mattress Toppers</h2>
                        <p className='font-size-1 pt-3'>Amani Mattress Toppers are the secret to transforming any mattress into a haven of comfort. With a plush layer of supportive foam, they provide an immediate improvement in sleep comfort and quality, helping to refresh both your mattress and your mornings.</p>
                    </div>
                    <div className="button text-center">
                      <Link to='/mattress-toppers' className="btn-purple text-white">View More</Link>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6">
                    <div className="product-image text-center">
                        <img src={mattressToppers} alt="product" />
                    </div>
                </div>
             </div>
             {/* Sofa Cum Bed */}
             <div className="row my-7">
               <div className="col-md-6 col-sm-6">
                  <div className="product-image">
                     <img src={sofaCumBed} alt="product"/>
                  </div>
               </div>

               <div className="col-md-6 col-sm-6 d-flex flex-column justify-content-between mt-mb-2">
                  <div className="product-text">
                     <h2 className='text-center custum-text-primary fw-600'>Sofa Cum Bed</h2>
                     <p className='font-size-1 pt-3'>Amani Sofa cum Beds are the epitome of functionality and comfort. Easily convertible and stylish, they are perfect for accommodating guests or for everyday use, maximizing your space without compromising on comfort.</p>
                  </div>
                  <div className="button text-center">
                     <Link to='/sofa-cum-bed' className="btn-purple text-white">View More</Link>
                  </div>
               </div>
                
             </div>
          </div>
       </div>
       <Footer />
    </>
  )
}

export default MoreProduct
