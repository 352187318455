import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
// import Filter from '../components/Filter.jsx'
import pillowData from '../data/pillow.js'
import Header from '../components/Header.jsx'
import Footer from '../components/Footer.jsx'


function Pillow() {
    useEffect( () => {
        document.title = 'Pillow - Amani';
        window.scrollTo(0, 0);
    })
  return (
    <>
        <Header />
        <div className="pillow-poster"></div>
        <div className="container mt-4 mb-7">
            <div className="row">   
                {/* <div className="col-md-3 col-sm-12">
                    <Filter />
                </div> */}
                {/* cards */}
                <div className="col-md-12 col-sm-12">
                    <p className='font-size-2 fw-600 custum-text-primary text-center'>Pillows</p>
                    <div className="matress-cars border-purple p-4">
                        <div className="row">
                            {
                                pillowData && pillowData.length > 0 && pillowData[0].products.map((ele, ind) => {
                                    return(
                                        <div className="col-md-3 col-sm-12" key={ind}>
                                            <div className="card border-0 matress-card">
                                                <div className="position-relative">
                                                    <div className='img-hover'>
                                                        <img src={ele?.img[0].main} className="card-img-top" alt="..." />
                                                        <div className='position-absolute view-product'>
                                                            <span className='me-2'>
                                                                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M19.925 7.65C18.2833 4.09167 14.5833 0.5 10 0.5C5.41667 0.5 1.71667 4.09167 0.075 7.65C0.0255612 7.76006 0 7.87935 0 8C0 8.12065 0.0255612 8.23994 0.075 8.35C1.71667 11.9083 5.41667 15.5 10 15.5C14.5833 15.5 18.2833 11.9083 19.925 8.35C19.9744 8.23994 20 8.12065 20 8C20 7.87935 19.9744 7.76006 19.925 7.65ZM10 12.1667C9.17591 12.1667 8.37033 11.9223 7.68512 11.4645C6.99992 11.0066 6.46587 10.3559 6.1505 9.59451C5.83514 8.83315 5.75262 7.99538 5.91339 7.18712C6.07417 6.37887 6.471 5.63644 7.05372 5.05372C7.63644 4.471 8.37887 4.07417 9.18712 3.91339C9.99538 3.75262 10.8332 3.83514 11.5945 4.1505C12.3559 4.46587 13.0066 4.99992 13.4645 5.68512C13.9223 6.37033 14.1667 7.17591 14.1667 8C14.1653 9.10466 13.7259 10.1637 12.9448 10.9448C12.1637 11.7259 11.1047 12.1653 10 12.1667Z" fill="white"/>
                                                                <path d="M10 10.5C11.3807 10.5 12.5 9.38071 12.5 8C12.5 6.61929 11.3807 5.5 10 5.5C8.61929 5.5 7.5 6.61929 7.5 8C7.5 9.38071 8.61929 10.5 10 10.5Z" fill="white"/>
                                                                </svg>
                                                            </span>
                                                            <Link to={`/pillow-product/${ele.id}`}>View Product</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                                <div className="pt-2">
                                                    <h5 className="font-size-1 fw-600">{ele.title}</h5>
                                                    <div className='text-center my-4'>
                                                        <Link to={`/pillow-product/${ele.id}`} className="btn-order font-size-1 fw-600">Order Now</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default Pillow
