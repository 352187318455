import React, {useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../assets/css/productview.css';
import protector1 from '../assets/img/Toppers/toppers.jpg';
import protector2 from '../assets/img/Toppers/toppers2.jpg';

const images = [
  { src: protector1, alt: 'Main Image' },
  { src: protector2, alt: 'Thumbnail 2' },
];

function MattressToperPreview() {
  const [mainImage, setMainImage] = useState(images[0]);
  const [size, setSize] = useState('');

  const handleImageClick = (image) => {
    setMainImage(image);
  };
  const handleSizeChange = (event) => {
    setSize(event.target.value)
  } 

  const handleOrderNow = () => {
    if (size) {
      const message = `I would like to order the size: ${size}`;
      const whatsappUrl = `https://wa.me/9933480777?text=${encodeURIComponent(message)}`;
      window.open(whatsappUrl);
      console.log(message);
    } else {
      alert("Please select a size before ordering.");
    }
  };

return (
  <>
    <Header />
      <div className="container mt-5 d-flex product-details">
        <div className="gallery">
            <div className="thumbnails me-5">
              {images.map((image, index) => (
                <img
                key={index}
                src={image.src}
                alt={image.alt}
                onClick={() => handleImageClick(image)}
                className={mainImage.src === image.src ? 'active' : ''}
                />
              ))}
            </div>

            <div className="main-image me-5">
              <img src={mainImage.src} alt={mainImage.alt} className='img-fluid'/>
            </div>

        </div>

        <div className="details">
            <h2 className='fw-bolde'>Mattress Topper</h2>
            {/* <p className='font-size-1 mb-0'>Luxury Line</p> */}
            <select value={size} onChange={handleSizeChange} className='select-custom'>
              <option value=''>Size Available</option>
              <option value="72 x 36 Inch">72 x 36 Inch</option>
              <option value="72 x 42 Inch">72 x 42 Inch</option>
              <option value="72 x 48 Inch">72 x 48 Inch</option>
              <option value="72 x 60 Inch">72 x 60 Inch</option>
              <option value="72 x 66 Inch">72 x 66 Inch</option>
              <option value="72 x 72 Inch">72 x 72 Inch</option>

              <option value="75 x 36 Inch">75 x 36 Inch</option>
              <option value="75 x 42 Inch">75 x 42 Inch</option>
              <option value="75 x 48 Inch">75 x 48 Inch</option>
              <option value="75 x 60 Inch">75 x 60 Inch</option>
              <option value="75 x 66 Inch">75 x 66 Inch</option>
              <option value="75 x 72 Inch">75 x 72 Inch</option>

              <option value="78 x 36 Inch">78 x 36 Inch</option>
              <option value="78 x 42 Inch">78 x 42 Inch</option>
              <option value="78 x 48 Inch">78 x 48 Inch</option>
              <option value="78 x 60 Inch">78 x 60 Inch</option>
              <option value="78 x 66 Inch">78 x 66 Inch</option>
              <option value="78 x 72 Inch">78 x 72 Inch</option>
            </select>
            <p>Selected Size: {size}</p>
            <div className="row">
                <div className="col-4 features">
                    <p>Breathable</p>
                </div>
                <div className="col-4 features">
                    <p>Waterproof</p>
                </div>
                <div className="col-4 features">
                <p>Ultra-Soft</p>
                </div>
                <div className="col-4 features">
                <p>Ultra-Durable</p>
                </div>
                <div className="col-4 features">
                    <p>Anti Bacterial</p>
                </div>
            </div>
            
            
            <button onClick={handleOrderNow} className='btn-purple text-white mt-4'>Order Now</button>
        </div>
      </div>
      <div className="container mt-7 mb-7">
          <h2 className='font-size-1 fw-600'>Additional Information</h2>
          <hr className='hr-yellow'/>
          <p className='font-size-1'>Ultra-soft, waterproof mattress protector guards against dust, liquid, and bacteria; easily washable for hygiene.
          </p>
      </div>
    <Footer />
  </>
)
}

export default MattressToperPreview
