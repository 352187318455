import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import mattressData from '../data/mattress';

function HomeProductCrousel() {

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2,
          slidesToSlide: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1
        }
      };

  return (
    <>
      <div className="container mb-7">
          <h2 className="fw-600 custum-text-primary Curated-text">Discover Our <span className='custum-font-secondary'>Curated Collection.</span></h2>
          <div className="linear-bg rounded-10 p-5 pd-sm">
            <Carousel responsive={responsive}>
                {
                    mattressData && mattressData.length > 0 && mattressData[4].products.slice(1,4).map((elem, index) => {
                       return ( 
                       <div className='testi-card' key={index}> 
                            <div class="card p-3">
                                <div style={{backgroundColor: "rgba(115, 115, 115, 0.5)"}} className='rounded-10'>
                                    <img src={Array.isArray(elem.img) ? elem.img[0].main : elem.img} class="card-img-top" alt="..." />
                                </div>
                                <div class="card-body text-center">
                                    <h5 class="font-size-2 fw-600 custum-text-primary my-3">Comfort Line</h5>
                                    <p class="font-size-1 fw-600 custum-text-primary pb-3">{elem.name}</p>
                                    <Link to={`/mattress-product/${elem.id}`} class="btn-yellow text-black fw-600 px-3 py-3">See More 
                                        <span className='ms-3'>
                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.6223 14.0906C7.46269 16.9917 7.00918 21.2676 6.81401 22.0323C6.60161 21.2676 6.17681 16.9917 0 14.0906C1.73947 13.4667 3.28392 12.3974 4.4784 10.9899C5.67289 9.58245 6.47539 7.88626 6.8054 6.07156C6.80782 6.0651 6.80782 6.05798 6.8054 6.05151C6.80679 6.05812 6.80679 6.06495 6.8054 6.07156C7.13863 7.88668 7.94366 9.58268 9.13995 10.9899C10.3363 12.3972 11.8819 13.4664 13.6223 14.0906Z" fill="#171717"></path><path d="M22.4999 10.104C18.6566 11.9082 18.3868 14.566 18.2634 15.0414C18.1371 14.566 17.8673 11.9082 14.0269 10.104C15.1089 9.71569 16.0697 9.05037 16.8127 8.17473C17.5558 7.29909 18.0551 6.24391 18.2605 5.11499C18.4686 6.24368 18.9696 7.29824 19.7134 8.17359C20.4572 9.04894 21.418 9.7145 22.4999 10.104Z" fill="#171717"></path><path d="M15.0088 3.0415C12.6724 4.13838 12.5059 5.7565 12.4255 6.04576C12.3509 5.75937 12.1844 4.13838 9.84229 3.0415C10.5037 2.80681 11.0912 2.40192 11.5453 1.86781C11.9995 1.33371 12.3041 0.689358 12.4284 0C12.5533 0.688277 12.8575 1.33165 13.3104 1.86552C13.7633 2.39939 14.349 2.80496 15.0088 3.0415Z" fill="#171717"></path>
                                        </svg>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )})
                }
                
            </Carousel>
          </div>
      </div>
    </>
  )
}

export default HomeProductCrousel
