import maxRB from '../assets/img/mattress/Standard-series/Max Rb/maxx RB.jpg';
import hapiness from '../assets/img/mattress/Standard-series/Happiness/hapiness.jpg';
import attraction from '../assets/img/mattress/Standard-series/ATTRACTION/attraction.jpg';
// premium series
import orthomemory from '../assets/img/mattress/Premium-series/Ortho Duet memory/Ortho Duet Memory.png';
import ortholife from '../assets/img/mattress/Premium-series/Ortho Life/ortho life.jpg';
import spineTheory from '../assets/img/mattress/Premium-series/Spine Theory/SPINE THEORY.jpg';
import cocoon from '../assets/img/mattress/Premium-series/Cocoon/cocoon.jpg';
import fourseason from '../assets/img/mattress/Premium-series/FOUR SEASONS/four season.jpg';
import spinemagic from '../assets/img/mattress/Premium-series/SPINE MAGIC/spine magic.jpg';
import attractionPremium from '../assets/img/mattress/Premium-series/Attraction premium/attreaction premium.jpg';

// comfort line
import evoke from '../assets/img/mattress/Comfort-line/EVOKE/Eveoke.jpg';
import gem from '../assets/img/mattress/Comfort-line/Gem150/Gem 150.jpg';
import imagi from '../assets/img/mattress/Comfort-line/IMAGIE/imagine.jpg';
import BondPro from '../assets/img/mattress/Comfort-line/Bond Pro/Bond Pro.jpg';
import Bliss from '../assets/img/mattress/Comfort-line/Bliss/Bliss.jpg';

// Signature lime
import atmosphere from '../assets/img/mattress/Signature-line/Atmostphere/atmosphere.jpg';
import bedofDreams from '../assets/img/mattress/Signature-line/Bed of Dreams/BED OF DREAMS.jpg';
import casagrand from '../assets/img/mattress/Signature-line/Casa Grand/Casa Grande1 .jpg';
import natureCure from '../assets/img/mattress/Signature-line/Natur Cure/nature cure.jpg';
// Spring Series
import psp from '../assets/img/mattress/Spring-series/Pocket Spring Premium/pocket spring premium.jpg';
import psf from '../assets/img/mattress/Spring-series/POCKET SPRING FR/POCKET SPRING FR.jpg';
import pspt from '../assets/img/mattress/Spring-series/POCKET SPRING Box _ PILLOW TOP/pocket spring pillow top.jpg';
// Luxury line
import gravity from '../assets/img/mattress/Luxury-line/Gravity/Gravity.jpg';
import paraside from '../assets/img/mattress/Luxury-line/PARADISE/Paradise.png';
import spinekear from '../assets/img/mattress/Luxury-line/Spine kear/SPINE KARE.jpg';
import latexHarmony from '../assets/img/mattress/Luxury-line/Latex Harmony/latex harmony.jpg';


// thumbnails for all catg
// standard series
import sthumb1 from '../assets/img/mattress/Standard-series/Happiness/_DSL02041.jpg';
import sthumb2 from '../assets/img/mattress/Standard-series/Happiness/_DSL02131.jpg';
import sthumb3 from '../assets/img/mattress/Standard-series/Happiness/_DSL05671.jpg';
import sthumb4 from '../assets/img/mattress/Standard-series/Max Rb/_DSL01691.jpg';
import sthumb5 from '../assets/img/mattress/Standard-series/Max Rb/_DSL_2334.webp';
import sthumb6 from '../assets/img/mattress/Standard-series/Max Rb/_DSL05711.jpg';
import sthumb7 from '../assets/img/mattress/Standard-series/ATTRACTION/_DSL02271.jpg';
import sthumb8 from '../assets/img/mattress/Standard-series/ATTRACTION/_DSL02331.jpg';
import sthumb9 from '../assets/img/mattress/Standard-series/ATTRACTION/_DSL02371.jpg';
// premium series
import psthumb4 from '../assets/img/mattress/Premium-series/Ortho Duet memory/_DSL07141.jpg';
import psthumb5 from '../assets/img/mattress/Premium-series/Ortho Duet memory/_DSL07201.jpg';
import psthumb6 from '../assets/img/mattress/Premium-series/Ortho Duet memory/_DSL072211.jpg';
import psthumb7 from '../assets/img/mattress/Premium-series/Ortho Life/_DSL08911.png';
import psthumb8 from '../assets/img/mattress/Premium-series/Ortho Life/_DSL08931.png';
import psthumb9 from '../assets/img/mattress/Premium-series/Ortho Life/ortho life (1).jpg';
import psthumb10 from '../assets/img/mattress/Premium-series/Spine Theory/_DSL14971.png';
import psthumb11 from '../assets/img/mattress/Premium-series/Spine Theory/spine theory1.jpg';
import psthumb12 from '../assets/img/mattress/Premium-series/Spine Theory/_DSL15061.png';
import psthumb13 from '../assets/img/mattress/Premium-series/Cocoon/_DSL09441.png';
import psthumb14 from '../assets/img/mattress/Premium-series/Cocoon/_DSL09461.png';
import psthumb15 from '../assets/img/mattress/Premium-series/Cocoon/_DSL09521.png';
import psthumb16 from '../assets/img/mattress/Premium-series/FOUR SEASONS/_DSL13701.png';
import psthumb17 from '../assets/img/mattress/Premium-series/FOUR SEASONS/_DSL13711.png';
import psthumb18 from '../assets/img/mattress/Premium-series/FOUR SEASONS/_DSL13771.png';
import psthumb19 from '../assets/img/mattress/Premium-series/SPINE MAGIC/1.jpg'
import psthumb20 from '../assets/img/mattress/Premium-series/SPINE MAGIC/2.jpg'
import psthumb21 from '../assets/img/mattress/Premium-series/SPINE MAGIC/3.jpg';
import psthumb22 from '../assets/img/mattress/Premium-series/Attraction premium/ap.jpg';
import psthumb23 from '../assets/img/mattress/Premium-series/Attraction premium/6.2.png';
import psthumb24 from '../assets/img/mattress/Premium-series/Attraction premium/attraction premium (1).jpg';

// Comfort Line
import clthumb19 from '../assets/img/mattress/Comfort-line/EVOKE/2.5.jpg';
import clthumb20 from '../assets/img/mattress/Comfort-line/EVOKE/2.2.jpg';
import clthumb21 from '../assets/img/mattress/Comfort-line/EVOKE/2.3.jpg';
import clthumb22 from '../assets/img/mattress/Comfort-line/Gem150/7.1.png';
import clthumb23 from '../assets/img/mattress/Comfort-line/Gem150/7.2.png';
import clthumb24 from '../assets/img/mattress/Comfort-line/Gem150/7.3.png';
import clthumb25 from '../assets/img/mattress/Comfort-line/IMAGIE/_DSL07881.jpg';
import clthumb26 from '../assets/img/mattress/Comfort-line/IMAGIE/_DSL07911.jpg';
import clthumb27 from '../assets/img/mattress/Comfort-line/IMAGIE/_DSL08111.jpg';
import clthumb28 from '../assets/img/mattress/Comfort-line/Bond Pro/1.jpg';
import clthumb29 from '../assets/img/mattress/Comfort-line/Bond Pro/2.jpg';
import clthumb30 from '../assets/img/mattress/Comfort-line/Bond Pro/4.jpg';
import clthumb31 from '../assets/img/mattress/Comfort-line/Bliss/1.jpg';
import clthumb32 from '../assets/img/mattress/Comfort-line/Bliss/2.jpg';
import clthumb33 from '../assets/img/mattress/Comfort-line/Bliss/3.jpg';

// Signature lime
import slthumb28 from '../assets/img/mattress/Signature-line/Atmostphere/_DSL17121.png';
import slthumb29 from '../assets/img/mattress/Signature-line/Atmostphere/_DSL17291.png';
import slthumb30 from '../assets/img/mattress/Signature-line/Atmostphere/_DSL17311.png';
import slthumb31 from '../assets/img/mattress/Signature-line/Bed of Dreams/_DSL16771.png';
import slthumb32 from '../assets/img/mattress/Signature-line/Bed of Dreams/_DSL16811.png';
import slthumb33 from '../assets/img/mattress/Signature-line/Bed of Dreams/_DSL16831.png';
import slthumb34 from '../assets/img/mattress/Signature-line/Casa Grand/_DSL13281.png';
import slthumb35 from '../assets/img/mattress/Signature-line/Casa Grand/_DSL13371.png';
import slthumb36 from '../assets/img/mattress/Signature-line/Casa Grand/_DSL13411.png';
import slthumb37 from '../assets/img/mattress/Signature-line/Natur Cure/_DSL11611.png';
import slthumb38 from '../assets/img/mattress/Signature-line/Natur Cure/_DSL11671.png';
import slthumb39 from '../assets/img/mattress/Signature-line/Natur Cure/_DSL11731.png';
// Spring Series
import slthumb40 from '../assets/img/mattress/Spring-series/Pocket Spring Premium/pocket sp pr.jpg';
import slthumb41 from '../assets/img/mattress/Spring-series/Pocket Spring Premium/pocket spring.jpg';
import slthumb42 from '../assets/img/mattress/Spring-series/Pocket Spring Premium/pocket spring premium1.jpg';
import slthumb43 from '../assets/img/mattress/Spring-series/POCKET SPRING FR/_DSL10641.jpg';
import slthumb44 from '../assets/img/mattress/Spring-series/POCKET SPRING FR/_DSL10691.jpg';
import slthumb45 from '../assets/img/mattress/Spring-series/POCKET SPRING FR/_DSL10881.jpg';
import slthumb46 from '../assets/img/mattress/Spring-series/POCKET SPRING Box _ PILLOW TOP/_DSL12241.png';
import slthumb47 from '../assets/img/mattress/Spring-series/POCKET SPRING Box _ PILLOW TOP/_DSL12341.png';
import slthumb48 from '../assets/img/mattress/Spring-series/POCKET SPRING Box _ PILLOW TOP/_DSL12241.png';
// Luxury line
import llthumb49 from '../assets/img/mattress/Luxury-line/Gravity/_DSL14131.png';
import llthumb50 from '../assets/img/mattress/Luxury-line/Gravity/_DSL14311.png';
import llthumb51 from '../assets/img/mattress/Luxury-line/Gravity/_DSL14401.png';
import llthumb52 from '../assets/img/mattress/Luxury-line/PARADISE/_DSL10211.png';
import llthumb53 from '../assets/img/mattress/Luxury-line/PARADISE/_DSL10241.png';
import llthumb54 from '../assets/img/mattress/Luxury-line/PARADISE/_DSL10331.png';
import llthumb55 from '../assets/img/mattress/Luxury-line/Spine kear/_DSL11871.png';
import llthumb56 from '../assets/img/mattress/Luxury-line/Spine kear/_DSL12021.png';
import llthumb57 from '../assets/img/mattress/Luxury-line/Spine kear/_DSL12091.png';
import llthumb58 from '../assets/img/mattress/Luxury-line/Latex Harmony/_DSL12771.png';
import llthumb59 from '../assets/img/mattress/Luxury-line/Latex Harmony/_DSL12831.png';
import llthumb60 from '../assets/img/mattress/Luxury-line/Latex Harmony/_DSL12871.png';

// icons
import qualityProduct from '../assets/img/features-icon/quality product.png';
import aeroSleepTechn from '../assets/img/features-icon/Aero- Sleep technology.png';
import bothSideUsable from '../assets/img/features-icon/both side usable.png';
import boxType from '../assets/img/features-icon/Box type Comford mattress for superior comfort.png';
import budgetFriendly from '../assets/img/features-icon/budget friendly.png';
import exportGrade from '../assets/img/features-icon/export-grade.png';
import durable from '../assets/img/features-icon/durable.png';
import innovativeMattressDesign from '../assets/img/features-icon/Innovative Mattress Design.png';
import mattressForIndianBack from '../assets/img/features-icon/Mattress for Indian back.png';
import orthoBondMattressFoam from '../assets/img/features-icon/Ortho Bond Mattress-foam encasement.png';
import orthoBondMattressIndian from '../assets/img/features-icon/Orthopaedic Mattress For Indian Back.png';
import properSpinal from '../assets/img/features-icon/Proper Spinal Alignment.png';
import superMemoryWorld from '../assets/img/features-icon/Super Memory World Class Pressure Relief Natural latex.png';
import uninterrupted from '../assets/img/features-icon/uninterrupted sleep.png';
import uniqueBoxTop from '../assets/img/features-icon/Unique box top design.png';
import zeroDisturbance from '../assets/img/features-icon/Zero Disturbance due to movement of partner.png';
import flagship from '../assets/img/features-icon/flagship model.png';
import attractiveDesign from '../assets/img/features-icon/attractive design.png';
import superlookModel from '../assets/img/features-icon/Superior look model.png';


const mattressData = [
    {
        category: 'Standard Series',
        products: [
            {
                id: '1',
                img: [
                    { main: hapiness},
                    { thumb1: sthumb1},
                    { thumb2: sthumb2},
                    { thumb3: sthumb3}
                ],
                name: 'Happiness',
                title: "Standard Series",
                thickness: [
                    { size: "4 ''" },
                    { size: "4.5 ''" },
                    { size: "5 ''" },
                    { size: "6 ''" }
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '12',
                warranty: '24',
                discription: 'Our sleep-induced spring mattress range is constructed with High-tech Carbon-Manganese pocket spring and HD foams which bring ultimate comfort and are highly durable at the same time.',
                features: [
                    { name: 'Both Side Quilting' },
                    { name: 'Coir Based Mattress' },
                    { name: 'Superior Comfort' },
                ],
                icons: [
                    { icon: budgetFriendly },
                    { icon: bothSideUsable },
                    { icon: qualityProduct },
                ],
                iconTitles: [
                    { title: 'Budget Friendly Mattress' },
                    { title: 'Both Side Usable Mattress' },
                    { title: 'Quality Product' },
                ]
            },
            {
                id: '2',
                img: [
                    { main: maxRB},
                    { thumb1: sthumb4},
                    { thumb2: sthumb5},
                    { thumb3: sthumb6}
                ],
                name: 'Maxx RB',
                title: "Standard Series",
                thickness: [
                    { size: "4 ''" },
                    { size: "4.5 ''" },
                    { size: "5 ''" },
                    { size: "6 ''" }
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '12',
                warranty: '24',
                discription: 'Our sleep induced spring mattress range are constructed with High tech Carbon-Manganese pocket spring and HD foams which bring ultimate comfort and are highly durable at the same time.',
                features: [
                    { name: 'Both Side Quilting' },
                    { name: 'Bonded Foam Based Mattress' },
                    { name: 'Made with Handpicked Materials' },
                    { name: 'Superior Comfort' },
                ],
                icons: [
                    { icon: budgetFriendly },
                    { icon: bothSideUsable },
                    { icon: qualityProduct },
                ],
                iconTitles: [
                    { title: 'Budget Friendly Mattress' },
                    { title: 'Both Side Usable Mattress' },
                    { title: 'Quality Product' },
                ]
            },
            {
                id: '3',
                img: [
                    { main: attraction},
                    { thumb1: sthumb7},
                    { thumb2: sthumb8},
                    { thumb3: sthumb9}
                ],
                name: 'Attraction',
                title: "Standard Series",
                thickness: [
                    { size: "4 ''" },
                    { size: "4.5 ''" },
                    { size: "5 ''" },
                    { size: "6 ''" }
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '12',
                warranty: '24',
                discription: 'Our sleep-induced spring mattress range is constructed with High-tech Carbon-Manganese pocket spring and HD foams which bring ultimate comfort and are highly durable at the same time.',
                features: [
                    { name: 'Both Side Quilting' },
                    { name: 'Bonded Foam Based Mattress' },
                    { name: 'Coir Based Mattress' },
                    { name: 'Superior Comfort' },
                ],
                icons: [
                    { icon: budgetFriendly },
                    { icon: bothSideUsable },
                    { icon: qualityProduct },
                ],
                iconTitles: [
                    { title: 'Budget Friendly Mattress' },
                    { title: 'Both Side Usable Mattress' },
                    { title: 'Quality Product' },
                ]
            },
        ]
    },
    {
        category: 'Amani Fitline',
        products: [
          
            {
                id: '10',
                img: [
                    { main: attractionPremium},
                    { thumb1: psthumb22},
                    { thumb2: psthumb23},
                    { thumb3: psthumb24}
                ],
                name: 'Attraction Premium',
                title: "Premium Series",
                thickness: [
                    { size: "4 ''" },
                    { size: "5 ''" },
                    { size: "6 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '12',
                warranty: '24',
                discription: "Our Spark mattress is designed with active support,  ensuring it's perfect for the Indian back. Quilted with high GSM fabric and featuring HD foam and HD coir, it provides ultimate comfort.",
                features: [
                    { name: 'Suitable for Indian Back' },
                    { name: 'Coir Based Mattress' },
                    { name: 'Both Side Quilting' },
                    { name: 'High GSM fabric' },
                ],
                icons: [
                    { icon: budgetFriendly },
                    { icon: bothSideUsable },
                    { icon: flagship },
                    { icon: attractiveDesign },
                    { icon: superlookModel },
                ],
                iconTitles: [
                    { title: 'Budget Friendly Matrress' },
                    { title: 'Both Side Usable Matrress' },
                    { title: 'Flagship Model' },
                    { title: 'Attractive Design' },
                    { title: 'Superior Look Model' },
                ]
            },
        ]
    },
    {
        category: 'Premium Series',
        products: [
            {
                id: '4',
                img: [
                    { main: orthomemory},
                    { thumb1: psthumb4},
                    { thumb2: psthumb5},
                    { thumb3: psthumb6}
                ],
                name: 'Ortho Duet Memory',
                title: "Premium Series",
                thickness: [
                    { size: "5 ''" },
                    { size: "6 ''" },
                    { size: "7 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '36',
                warranty: '60',
                discription: 'Combination of ultra plush support layer and high density. Ortho bond Foam as core for adequate body support. Ortho Duet Memory is designed for our sleeping partners who want to have a good orthopaedic mattress with the feel of memory foam at reasonable prices. Memory Foam is designed by NASA scientists to provide ultimate comfort while sleeping as it naturally takes the shape and contour person lying on it. It is a great feature that allows the right zone of comfort for them indian back.',
                features: [
                    { name: 'Quilted with High Density Foam' },
                    { name: 'Both Side Quilting' },
                    { name: 'Superior Comfort' },
                ],
                icons: [
                    { icon: budgetFriendly },
                    { icon: bothSideUsable },
                ],
                iconTitles: [
                    { title: 'Budget Friendly Mattress' },
                    { title: 'Both Side Usable Mattress' },
                ]
            },
            {
                id: '5',
                img: [
                    { main: ortholife},
                    { thumb1: psthumb7},
                    { thumb2: psthumb8},
                    { thumb3: psthumb9}
                ],
                name: 'Ortho Life',
                title: "Premium Series",
                thickness: [
                    { size: "4 ''" },
                    { size: "5 ''" },
                    { size: "6 ''" },
                    { size: "8 ''" }
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '36',
                warranty: '84',
                discription: 'The Doctor Recommended mattress by Doctors of Chiropractic, which provides a right balance of comfort and support. Especially designed for Indian back for Firmness and Support.',
                features: [
                    { name: 'Dr. Recommended Mattress' },
                    { name: 'Both Side Quilting' },
                    { name: 'Both Side Quilting Mattress in Knitted Fabric' },
                ],
                icons: [
                    { icon: properSpinal },
                    { icon: durable },
                ],
                iconTitles: [
                    { title: 'Proper Spinal Alignment' },
                    { title: 'Durable' },
                ]
            },
            {
                id: '6',
                img: [
                    { main: spineTheory},
                    { thumb1: psthumb10},
                    { thumb2: psthumb11},
                    { thumb3: psthumb12}
                ],
                name: 'Spine Theory',
                title: "Premium Series",
                thickness: [
                    { size: "6 ''" },
                    { size: "8 ''" },
                    { size: "10 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '36',
                warranty: '84',
                discription: 'Cellular Memory Foam works in Harmony with Bonded Foam to provide perfect pressure to your body for comfort and for a sound night sleep.',
                features: [
                    { name: 'Dr. Recommended Mattress' },
                    { name: 'Both Side Quilting' },
                    { name: 'Open Cell Structure Memory foam For Air Ventilation' },
                    { name: 'Perfect Memory foam Mattress' },
                ],
                icons: [
                    { icon: bothSideUsable },
                    { icon: durable },
                ],
                iconTitles: [
                    { title: 'Both Side Usable Mattress' },
                    { title: 'Durable' },
                ]
            },
            {
                id: '7',
                img: [
                    { main: cocoon},
                    { thumb1: psthumb13},
                    { thumb2: psthumb14},
                    { thumb3: psthumb15}
                ],
                name: 'Cocoon',
                title: "Premium Series",
                thickness: [
                    { size: "4+2 ''" },
                    { size: "6+2 ''" },
                    { size: "8+2 ''" },
                    { size: "10+2 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '48',
                warranty: '120',
                discription: 'Combination of 2" high density memory foam, quilted with 1" of memory foam in Pillow top construction in combination with high density Ortho Bond foam as core for body contouring support comfort.',
                features: [
                    { name: 'Pillow Top for Superior Look and Comfort' },
                    { name: 'Quilting with 1inch High Density Memory Foam for Body Contouring Effect' },
                    { name: 'Pressure Relieving Comfort' },
                    { name: 'Anti-Skid Fabric used in Bottom' },
                    { name: 'Unique Flanging top for Maximum edge Comfort' },
                ],
                icons: [
                    { icon: orthoBondMattressIndian },
                    { icon: mattressForIndianBack },
                ],
                iconTitles: [
                    { title: 'OrthoPaedic Mattress For Indian Back' },
                    { title: 'Mattress For Indian Back' },
                ]
            },
            {
                id: '8',
                img: [
                    { main: spinemagic},
                    { thumb1: psthumb19},
                    { thumb2: psthumb20},
                    { thumb3: psthumb21}
                ],
                name: 'Spine Magic',
                title: "Premium Series",
                thickness: [
                    { size: "6 ''" },
                    { size: "8 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '36',
                warranty: '84',
                discription: 'With the high Density bonded foam sandwiched between rubberized coir, the SPINE MAGIC provides a springy yet firm base. And soft foam on one side along with the additional layer of PU foam perfectly blends comfort and aesthetic value.',
                features: [
                    { name: 'Coir Based Mattress' },
                    { name: 'Both Side Quilting' },
                    { name: 'Superior Comfort' },
                    { name: 'Made with Handpicked Materials' },
                ],
                icons: [
                    { icon: budgetFriendly },
                    { icon: bothSideUsable },
                    { icon: qualityProduct },
                    { icon: durable },
                ],
                iconTitles: [
                    { title: 'Budget Friendly Mattress' },
                    { title: 'Both Side Usable Mattress' },
                    { title: 'Quality Product' },
                    { title: 'Durable' },
                ]
            },
            {
                id: '9',
                img: [
                    { main: fourseason},
                    { thumb1: psthumb16},
                    { thumb2: psthumb17},
                    { thumb3: psthumb18}
                ],
                name: 'Four Season',
                title: "Premium Series",
                thickness: [
                    { size: "8 ''" },
                    { size: "10 ''" },
                    { size: "12 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '48',
                warranty: '84',
                discription: 'Four Seasons is an ultra-luxury Hybrid Spring mattress which combines the benefit of body contouring foam and do not disturb HD Pocket Springs. Ideal mattress for newly wedded couples with an active lifestyle the mattress while the mattress maintains the optimum support for back.',
                features: [
                    { name: 'Perfect Mattress For Newly Web Couples' },
                    { name: 'Hybrid Spring Mattress' },
                    { name: 'dr. Recommended Mattress' },
                    { name: 'Antiskid Fabric Used in Bottom' },
                ],
                icons: [
                    { icon: zeroDisturbance },
                    { icon: boxType },
                ],
                iconTitles: [
                    { title: 'Zero Disturbance Due to Partner Movement' },
                    { title: 'Box type mattress for superior comfort' },
                ]
            },
            {
                id: '10',
                img: [
                    { main: attractionPremium},
                    { thumb1: psthumb22},
                    { thumb2: psthumb23},
                    { thumb3: psthumb24}
                ],
                name: 'Attraction Premium',
                title: "Premium Series",
                thickness: [
                    { size: "4 ''" },
                    { size: "5 ''" },
                    { size: "6 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '12',
                warranty: '24',
                discription: "Our Spark mattress is designed with active support,  ensuring it's perfect for the Indian back. Quilted with high GSM fabric and featuring HD foam and HD coir, it provides ultimate comfort.",
                features: [
                    { name: 'Suitable for Indian Back' },
                    { name: 'Coir Based Mattress' },
                    { name: 'Both Side Quilting' },
                    { name: 'High GSM fabric' },
                ],
                icons: [
                    { icon: budgetFriendly },
                    { icon: bothSideUsable },
                    { icon: flagship },
                    { icon: attractiveDesign },
                    { icon: superlookModel },
                ],
                iconTitles: [
                    { title: 'Budget Friendly Matrress' },
                    { title: 'Both Side Usable Matrress' },
                    { title: 'Flagship Model' },
                    { title: 'Attractive Design' },
                    { title: 'Superior Look Model' },
                ]
            },
        ]
    },
    {
        category: 'Comfort Line',
        products: [
            {
                id: '11',
                img: [
                    { main: evoke},
                    { thumb1: clthumb19},
                    { thumb2: clthumb20},
                    { thumb3: clthumb21}
                ],
                name: 'Evoke',
                title: "Comfort Line",
                thickness: [
                    { size: "5 ''" },
                    { size: "6 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '36',
                warranty: '60',
                discription: 'Combination of ultra plush support layer and high density. Ortho bond Foam as core for adequate body support.',
                features: [
                    { name: 'Quilted with High Density Foam' },
                    { name: 'Made with Handpicked Materials' },
                    { name: 'Both Side Quilting' },
                    { name: 'Superior Comfort' },
                ],
                icons: [
                    { icon: budgetFriendly },
                    { icon: bothSideUsable },
                ],
                iconTitles: [
                    { title: 'Budget Friendly Matrress' },
                    { title: 'Both Side Usable Matrress' },
                ]
            },
            {
                id: '12',
                img: [
                    { main: gem},
                    { thumb1: clthumb22},
                    { thumb2: clthumb23},
                    { thumb3: clthumb24}
                ],
                name: 'Gem 150',
                title: "Comfort Line",
                thickness: [
                    { size: "4 '' + 2'' Pillow Top" },
                    { size: "6 '' + 2'' Pillow Top" },
                    { size: "8 '' + 2'' Pillow Top" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '24',
                warranty: '60',
                discription: 'Customer can choose between coir based or bonded based mattress. Same has to be mentioned while placing order.',
                features: [
                    { name: 'Dr.Recommended Mattress' },
                    { name: 'Perfect Mattress for Newly Wed Couples' },
                    { name: 'Anti Fabric used in Bottom' },
                ],
                icons: [
                    { icon: budgetFriendly },
                    { icon: uninterrupted },
                    { icon: durable },
                    { icon: qualityProduct },
                    { icon: exportGrade },
                ],
                iconTitles: [
                    { title: 'Budget Friendly Matrress' },
                    { title: 'Uninterrupted Sleep' },
                    { title: 'Durable' },
                    { title: 'Quality Product' },
                    { title: 'Export-Grade Mattress' },
                ]
            },
            {
                id: '13',
                img: [
                    { main: BondPro},
                    { thumb1: clthumb28},
                    { thumb2: clthumb29},
                    { thumb3: clthumb30}
                ],
                name: 'Bond Pro',
                title: "Comfort Line",
                thickness: [
                    { size: "5 ''" },
                    { size: "6 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '36',
                warranty: '60',
                discription: 'Bonded foam forms to core of a foam mattress, keeping it firm and strong while double side quilting and the additional layer of PU foam adds a touch of softness to the surface.',
                features: [
                    { name: 'Both Side Quilting' },
                    { name: 'Superior Comfort' },
                    { name: 'Made with Handipicked Materials' },
                ],
                icons: [
                    { icon: budgetFriendly },
                    { icon: bothSideUsable },
                    { icon: qualityProduct },
                    { icon: durable },
                ],
                iconTitles: [
                    { title: 'Budget Friendly Matrress' },
                    { title: 'Both Side Usable Matrress' },
                    { title: 'Quality Product' },
                    { title: 'Durable' },
                ]
            },
            {
                id: '14',
                img: [
                    { main: Bliss},
                    { thumb1: clthumb31},
                    { thumb2: clthumb32},
                    { thumb3: clthumb33}
                ],
                name: 'Bliss',
                title: "Comfort Line",
                thickness: [
                    { size: "5 ''" },
                    { size: "6 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '36',
                warranty: '60',
                discription: 'Amani bliss is all the support your body needs. It is both firm and provides enhanced durability to make sure that you can sleep well for a long time',
                features: [
                    { name: 'Both Side Quilting' },
                    { name: 'Superior Comfort' },
                    { name: 'Made with Handipicked Materials' },
                ],
                icons: [
                    { icon: budgetFriendly },
                    { icon: bothSideUsable },
                    { icon: qualityProduct },
                    { icon: durable },
                ],
                iconTitles: [
                    { title: 'Budget Friendly Matrress' },
                    { title: 'Both Side Usable Matrress' },
                    { title: 'Quality Product' },
                    { title: 'Durable' },
                ]
            },
            {
                id: '15',
                img: [
                    { main: imagi},
                    { thumb1: clthumb25},
                    { thumb2: clthumb26},
                    { thumb3: clthumb27}
                ],
                name: 'Imagine',
                title: "Comfort Line",
                thickness: [
                    { size: "4 '' + 2'' Pillow Top" },
                    { size: "6 '' + 2'' Pillow Top" },
                    { size: "8 '' + 2'' Pillow Top" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '36',
                warranty: '60',
                discription: 'Budget Friendly Pillow Top mattress made in orthobond core in combination with plush super soft foam at a reasonable price.',
                features: [
                    { name: 'pillow Top Mattress' },
                    { name: 'Spine Alignment Technology used' },
                    { name: 'Antiskid Fabric used in Bottom' },
                ],
                icons: [
                    { icon: budgetFriendly },
                    { icon: bothSideUsable },
                ],
                iconTitles: [
                    { title: 'Budget Friendly Matrress' },
                    { title: 'Both Side Usable Matrress' },
                ]
            },
        ]
    },
    {
        category: 'Signature Line',
        products: [
            {
                id: '16',
                img: [
                    { main: atmosphere},
                    { thumb1: slthumb28},
                    { thumb2: slthumb29},
                    { thumb3: slthumb30}
                ],
                name: 'Atmosphere',
                title: "Signature Line",
                thickness: [
                    { size: "7 ''" },
                    { size: "9 ''" },
                    { size: "11 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                warranty: '12',
                discription: 'Atmostphere is a soft memory foam mattress made in combination of 4" Memory Foam and new technology HR foam. This creates the ultimate atmostphere for sleeping for customers who are looking for luxurious sleep experience with true one of a kind body contouring mattress.',
                features: [
                    { name: 'Luxurious And Soft Feel' },
                    { name: 'Hyper Foam With New Support Foam' },
                    { name: 'Cool Gel Memory Foam Regulates Your Temperature While You Sleep' },
                    { name: 'World Class Super Memory Foam' },
                ],
                icons: [
                    { icon: innovativeMattressDesign },
                    { icon: superMemoryWorld },
                ],
                iconTitles: [
                    { title: 'Innovative Mattress Design' },
                    { title: 'Super Memory World Class Pressure Relief' },
                ]
            },
            {
                id: '17',
                img: [
                    { main: bedofDreams},
                    { thumb1: slthumb31},
                    { thumb2: slthumb32},
                    { thumb3: slthumb33}
                ],
                name: 'Bed Of Dreams',
                title: "Signature Line",
                thickness: [
                    { size: "10 ''" },
                    { size: "12 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                warranty: '108',
                discription: 'Bed Of Dreams is a perfect Natural latex Hybrid mattress combined with plush feel of innersprings. Responsive, ultra- breathable natural talalay latex has a weightless feel that offers unsurpassed pressure point relief as it keeps you cooler through the night.',
                features: [
                    { name: 'Latex Foam' },
                    { name: 'Body Sink Convoluted Foam' },
                    { name: 'Micro Pocket Springs' },
                    { name: 'Bouncy PU Foam' },
                    { name: 'Hard Foam Encasement' },
                ],
                icons: [
                    { icon: exportGrade },
                ],
                iconTitles: [
                    { title: 'Export-Grade Mattress' },
                ]
                
            },
            {
                id: '18',
                img: [
                    { main: casagrand},
                    { thumb1: slthumb34},
                    { thumb2: slthumb35},
                    { thumb3: slthumb36}
                ],
                name: 'Casa Grand',
                title: "Signature Line",
                thickness: [
                    { size: "10 ''" },
                    { size: "12 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                warranty: '108',
                discription: 'Casa Grande offers ultimate comfort with body sink foam quilted in ultra-luxurious fabric. Its unique box top with pillow top construction provides perfect softness and support. Natural latex and memory foam work together for a "floating in the air" experience, while the UDH pocket ',
                features: [
                    { name: 'Body Sink Foam Quilted In ultra-Luxurious Fabric' },
                    { name: 'Unique Box top with pillow Top Construction Mattress' },
                    { name: 'Natural Latex Works in Harmony with Memory Foam Which Provides Perfect Balance to Body while Giving "Floating in the Air" Experience ' },
                    { name: 'Bouncy PU Foam' },
                    { name: 'Hard Foam Encasement' },
                ],
                icons: [
                    { icon: exportGrade },
                ],
                iconTitles: [
                    { title: 'Export-Grade Mattress' },
                ]
            },
            {
                id: '19',
                img: [
                    { main: natureCure},
                    { thumb1: slthumb37},
                    { thumb2: slthumb38},
                    { thumb3: slthumb39}
                ],
                name: 'Nature Cure',
                title: "Signature Line",
                thickness: [
                    { size: "6 ''" },
                    { size: "8 ''" },
                    { size: "10 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                warranty: '120',
                discription: 'The Seven zone 100% Natural latex Sheet is imported from the best Sources in Belgium for ultra luxurious comfort & Support. The sheet is carefully divided into 7 Zones of Scientific arrangement of air ventilation holes to create required support & feel in each zone, making it a total balanced support to your entire body.',
                features: [
                    { name: '100% Natural Belgain Latex' },
                    { name: 'Organic Bamboo Infused Mattress Cover' },
                    { name: 'Both Side Quilting' },
                    { name: 'Breathable Mattress' },
                ],
                icons: [
                    { icon: zeroDisturbance },
                    { icon: boxType },
                ],
                iconTitles: [
                    { title: 'Zero Disturbance Due to Partner Movement' },
                    { title: 'Box Type Mattress For Superior Comfort' },
                ]
            },
        ]
    },
    {
        category: 'Spring Series',
        products: [
            {
                id: '20',
                img: [
                    { main: psp},
                    { thumb1: slthumb40},
                    { thumb2: slthumb41},
                    { thumb3: slthumb42}
                ],
                name: 'Pocket Spring Premium',
                title: "Spring Series",
                thickness: [
                    { size: "6 ''" },
                    { size: "8 ''" },
                    { size: "10 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '36',
                warranty: '84',
                discription: 'Our sleep-induced spring mattress range is constructed with High-tech Carbon-Manganese pocket spring and HD foams which bring ultimate comfort and are highly durable at the same time.',
                features: [
                    { name: 'Foam Encasing For Superior Edge Comfort' },
                    { name: 'Superior Knitted fabric for plush Spring Mattress Feel' },
                ],
                icons: [
                    { icon: orthoBondMattressFoam },
                    { icon: zeroDisturbance },
                ],
                iconTitles: [
                    { title: 'Foam Encasement Technology' },
                    { title: 'Zero Disturbance Due to Partner Movement' },
                ]
                
            },
            {
                id: '21',
                img: [
                    { main: psf},
                    { thumb1: slthumb43},
                    { thumb2: slthumb44},
                    { thumb3: slthumb45}
                ],
                name: 'Pocket Spring FR',
                title: "Spring Series",
                thickness: [
                    { size: "6 ''" },
                    { size: "8 ''" },
                    { size: "10 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '36',
                warranty: '84',
                discription: 'Designed with foam encasing for superior edge comfort and a box top made with high-density foam for ultimate relaxation. The mattress features HD pocket springs for enhanced support and durability, along with cotton felt for added softness and comfort.',
                features: [
                    { name: 'Foam Encasing For Superior Edge Comfort' },
                ],
                icons: [
                    { icon: orthoBondMattressFoam },
                    { icon: zeroDisturbance },
                ],
                iconTitles: [
                    { title: 'Foam Encasement technology' },
                    { title: 'Zero Disturbance Due to Partner Movement ' },
                ]
                
            },
            {
                id: '22',
                img: [
                    { main: pspt},
                    { thumb1: slthumb46},
                    { thumb2: slthumb47},
                    { thumb3: slthumb48}
                ],
                name: 'Pocket Spring Pillow Top',
                title: "Spring Series",
                thickness: [
                    { size: "6 ''" },
                    { size: "8 ''" },
                    { size: "10 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '36',
                warranty: '84',
                discription: 'Our sleep-induced spring mattress range is constructed with High-tech Carbon-Manganese pocket spring and HD foams which bring ultimate comfort and are highly durable at the same time.',
                features: [
                    { name: 'Foam Encasing For Superior Edge Comfort' },
                    { name: 'Box Top Made In High Density foam For Ultimate Comfort' },
                ]
            },
        ]
    },
    {
        category: 'Luxury Line',
        products: [
            {
                id: '23',
                img: [
                    { main: gravity},
                    { thumb1: llthumb49},
                    { thumb2: llthumb50},
                    { thumb3: llthumb51}
                ],
                name: 'Gravity',
                title: "Luxury Line",
                thickness: [
                    { size: "6 ''" },
                    { size: "8 ''" },
                    { size: "10 ''" },
                    { size: "12 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '60',
                warranty: '120',
                discription: 'A true body contouring Memory Foam with Firmtech High Resilency Foam Core will give you supportive yet Luxurious Sleep Experience. When you lie down on 3 inches of high density memory foam on top surface coupled with firmer responsive core, your body takes a body contouring comfort while high support comes from responsive core. This mattress works as an orthopaedic mattress alternative- in a ultra luxurious way.',
                features: [
                    { name: 'Ultra Luxurious Knitted Fabric' },
                    { name: 'NASA Technology' },
                    { name: 'Pressure Relieving Comfort' },
                    { name: 'Unique Flanging Top For Maximum Edge Eomfort' },
                    { name: 'Memory Foam Quilted Top With Heavy soft Knitted Fabric' },
                ],
                icons: [
                    { icon: properSpinal },
                    { icon: orthoBondMattressIndian },
                ],
                iconTitles: [
                    { title: 'Proper Spinal Alignment' },
                    { title: 'Orthopaedic Mattress For Indian Back ' },
                ]
                
            },
            {
                id: '24',
                img: [
                    { main: paraside},
                    { thumb1: llthumb52},
                    { thumb2: llthumb53},
                    { thumb3: llthumb54}
                ],
                name: 'Paradise',
                title: "Luxury Line",
                thickness: [
                    { size: "6 ''" },
                    { size: "8 ''" },
                    { size: "10 ''" },
                    { size: "12 ''" }
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '60',
                warranty: '120',
                discription: 'Paradise is a great alternative for those seeking a 100% Latex Mattress. Fueled by 2 inches of 7-Zone 100% Natural Belgian latex combined with support of Firmtech high resilency Foam core makes it an ultra-durable and more comfortable mattress than 100% latex mattress. The 7-Zone latex produces a very quiet, balanced support experience, while the High density Firmtech resilency foam absorbs motion and provides additional support to your body.',
                features: [
                    { name: '100% Natural Belgian Latex' },
                    { name: 'Quilted with Body Sink Foam For Luxury Feel' },
                    { name: 'Pressure Relieving Comfort' },
                    { name: 'Unique Flanging Top For Maximum Edge Eomfort' },
                    { name: 'Antiskid Fabric Used In Bottom' },
                ],
                icons: [
                    { icon: orthoBondMattressIndian },
                ],
                iconTitles: [                    
                    { title: 'Orthopaedic Mattress For Indian Back ' },
                ]
                
            },
            {
                id: '25',
                img: [
                    { main: spinekear},
                    { thumb1: llthumb55},
                    { thumb2: llthumb56},
                    { thumb3: llthumb57}
                ],
                name: 'Spine kear',
                title: "Luxury Line",
                thickness: [
                    { size: "7 ''" },
                    { size: "9 ''" },
                    { size: "11 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '48',
                warranty: '120',
                discription: 'Customer can choose between Spring and Orthobond Foam construction. Same has to be mentioned while placing order.',
                features: [
                    { name: 'Anti-Skid Fabric Used in Bottom' },
                    { name: 'Unique Mattress Designing' },
                    { name: 'Pressure Relieving Comfort' },
                    { name: 'Box Top for Superior look and Comfort' },
                ],
                icons: [
                    { icon: orthoBondMattressIndian },
                    { icon: mattressForIndianBack },
                    { icon: uniqueBoxTop },
                    { icon: orthoBondMattressFoam },
                ],
                iconTitles: [
                    
                    { title: 'Orthopaedic Mattress For Indian Back ' },
                    { title: 'Mattress For Indian Back ' },
                    { title: 'Unique Box Top Design ' },
                    { title: 'Ortho Bond mattress ' },
                ]
            },
            {
                id: '26',
                img: [
                    { main: latexHarmony},
                    { thumb1: llthumb58},
                    { thumb2: llthumb59},
                    { thumb3: llthumb60}
                ],
                name: 'Latex Harmony',
                title: "Luxury Line",
                thickness: [
                    { size: "6 ''" },
                    { size: "8 ''" },
                    { size: "10 ''" },
                ],
                sizes: [
                    { size: "72x36 Inch" },
                    { size: "72x42 Inch" },
                    { size: "72x48 Inch" },
                    { size: "72x60 Inch" },
                    { size: "72x66 Inch" },
                    { size: "72x72 Inch" },

                    { size: "75x36 Inch" },
                    { size: "75x42 Inch" },
                    { size: "75x48 Inch" },
                    { size: "75x60 Inch" },
                    { size: "75x66 Inch" },
                    { size: "75x72 Inch" },

                    { size: "78x36 Inch" },
                    { size: "78x42 Inch" },
                    { size: "78x48 Inch" },
                    { size: "78x60 Inch" },
                    { size: "78x66 Inch" },
                    { size: "78x72 Inch" },

                    { size: "84x36 Inch" },
                    { size: "84x48 Inch" },
                    { size: "84x60 Inch" },
                    { size: "84x72 Inch" },

                ],
                guarantee: '48',
                warranty: '84',
                discription: 'Latex Harmony is made with special combination of unique layers to provide a highly supportive and medically beneficial comfort for good spine and restful night. Made with 2 inches of 100% Natural Belgian Latex on top of High density Orthobond core which is medically best to provide firm support to body.',
                features: [
                    { name: '100% Natural Belgain Latex' },
                    { name: 'Dr. recommended Mattress' },
                    { name: 'Anti Bacterial Fabric Used for a Stress Free Sleep' },
                ],
                icons: [
                    { icon: uniqueBoxTop },
                    { icon: aeroSleepTechn },
                ],
                iconTitles: [                    
                    { title: 'Unique Box Top Design' },
                    { title: 'Areo-Sleep Technology' },
                ]
                
            },
        ]
    },
   
];

export default mattressData;
