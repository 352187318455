import React, { useState, useEffect } from 'react';
import '../assets/css/productview.css';
import { useParams } from 'react-router-dom';

function ProductView({ data }) {
    const { productId } = useParams();
    const product = data.flatMap(category => category.products).find((item) => item.id === productId);

    // Handling both cases: Array of images or single image
    const images = product && Array.isArray(product.img) ? 
        product.img.map((imgObj, index) => ({
            src: Object.values(imgObj)[0],  // Use the first value in the object
            alt: `${product.name} Image ${index + 1}`,
        })) 
        : product ? [{ src: product.img, alt: `${product.name} Image` }] : [];

    const [mainImage, setMainImage] = useState(images[0] || {});
    const [size, setSize] = useState('');
    const [thickness, setThickness] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [productId]);

    const handleImageClick = (image) => {
        setMainImage(image);
    };

    const handleSizeChange = (event) => {
        setSize(event.target.value);
    };

    const handleThicknessChange = (event) => {
        setThickness(event.target.value);
    };

    const handleOrderNow = () => {
        const requiresThickness = product.thickness !== undefined; // Check if thickness is needed
    
        if (size && (thickness || !requiresThickness) && product.name) {
            const message = `I would like to order the size: ${size} ${requiresThickness ? `and thickness: ${thickness}` : ""} of ${product.name}`;
            const whatsappUrl = `https://wa.me/9933480777?text=${encodeURIComponent(message)}`;
            window.open(whatsappUrl);
        } else {
            alert(`Please select ${!size ? "a size" : "thickness"} before ordering.`);
        }
    };
    

    if (!product) {
        return <p>Product not found.</p>;
    }

    const { title, name, guarantee, warranty, discription } = product;

    return (
        <>
            <div className="container mt-5 mb-5 d-flex product-details">
                <div className="gallery">
                    <div className="thumbnails">
                        {images.map((image, index) => (
                            <img
                                key={index}
                                src={image.src}
                                alt={image.alt}
                                onClick={() => handleImageClick(image)}
                                className={mainImage.src === image.src ? 'active' : ''}
                            />
                        ))}
                    </div>

                    <div className="main-image">
                        <img src={mainImage.src} alt={mainImage.alt} className='img-fluid' />
                    </div>
                </div>

                <div className="details">
                    <h2 className='fw-bold'>{name}</h2>
                    <p className='font-size-1 mb-3'>{title}</p>
                    {
                        product.sizes && product.sizes.length > 0 ? (
                            <select value={size} onChange={handleSizeChange} className='select-custom'>
                                <option value=''>Size Available</option>
                                {product.sizes && product.sizes.map((dim,ind) =>{
                                return <option key={ind} value={dim.size}>{dim.size}</option>
                                    })
                                }
                            </select>
                        ): null
                    }
                    {
                        product.thickness && product.thickness.length > 0 ? (
                            <select value={thickness} onChange={handleThicknessChange} className='select-custom'>
                                <option value="">Select Thickness</option>
                                {product.thickness && product.thickness.map((thick, index) => (
                                    <option key={index} value={thick.size}>{thick.size}</option>
                                ))}
                            </select>
                        ): null
                    }

                    {size && (
                        <p>Selected Size: <span className='fw-bold'>{size}</span></p>
                    )}
                    {thickness && (
                        <p>Selected Thickness: <span className='fw-bold'>{thickness}</span></p>
                    )}
                    
                    <div className="row mt-5">
                        {
                            product.iconTitles?.map((ele, index) => {
                                return (
                                    <div key={index} className="col-md-4 col-6 mb-4 text-center">
                                        <img src={product.icons[index].icon} alt={ele.title} className="mb-2" />
                                        <p>{ele.title}</p>
                                    </div>
                                );
                            })
                        }
                    </div>

                    <button onClick={handleOrderNow} className='btn-purple text-white mt-5'>Order Now</button>
                </div>
            </div>
            <div className="container mb-5">
                <h2 className='font-size-1 fw-600'>Additional Information</h2>
                <hr className='hr-yellow'/>
                <p className='font-size-1'>{discription}</p>
                <div className="row">
                    {product.features && product.features.length > 0 && (
                        <div className="col-6 border-right">
                            <h2 className='font-size-1 fw-600'>Features</h2>
                            <ul className='features-list'>
                                {product.features?.map((ele) => {
                                    return (<li key={ele.id} className='font-size-1 mb-2'>{ele.name}</li>)
                                })}
                            </ul>
                        </div>
                    )}
                    <div className="col-6 ps-5">
                        {guarantee && (
                            <h2 className='font-size-1 fw-600'>Other Details</h2>
                        )}
                        <div className='d-flex gap-4 text-center mt-4'>
                            {guarantee && (
                                <p className='fw-semibold'><span className='fw-bold font-size-2 custum-text-primary'>{guarantee}</span> <br />Months <br /> Guarantee</p>
                            )}
                            {warranty && (
                                <p className='fw-semibold'><span className='fw-bold font-size-2 custum-text-primary'>{warranty}</span> <br />Months <br /> Warranty</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default ProductView;
