import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/home.css';
import matress from '../assets/img/matress.png';
import pilow from '../assets/img/pillow-item.png';
import moreitem from '../assets/img/more.png';
import HomeTabs from '../components/HomeTabs';
// import Testimonial from '../components/Testimonial';
import HomeProductCrousel from '../components/HomeProductCrousel';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Home() {
    useEffect(() => {
        document.title = 'Amani';
      window.scrollTo(0, 0);

    }, [])
  return (
    <>
        <Header />
        <div className="App home-poster"></div>

        <div className="collection-section mt-8">
            <div className="container text-center">
                <p className="pillow-purple text-center text-white font-size-2 fw-600 d-inline-block text-center">Explore Our 
                    <span className='custum-font-secondary'> Comfort</span> Collection
                </p>
            </div>
            <div className="collection-items mb-7 bg-purple">
                <div className="container">
                    <div className="row justify-content-between sm-gap-1">

                        <div className="col-md-4">
                            <div className="collection-item">
                                <div className='position-relative'>
                                    <Link to='/mattress'>
                                        <img src={matress} alt="collection item" className='img-fluid'/>
                                    </Link>
                                    <div className="collection-item-content position-absolute text-white">
                                        <h2 className='mb-0 pt-1'>Mattress</h2>
                                        <p className='mb-0 font-size-1 pt-2'>Unlock the secret to a good night's sleep with our ultra comfy-mattresses.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="collection-item">
                                <div className='position-relative'>
                                    <Link to='/pillow'>
                                     <img src={pilow} alt="collection item" className='img-fluid'/>
                                    </Link>
                                    <div className="collection-item-content position-absolute text-white">
                                        <h2 className='mb-0 pt-1'>Pillow</h2>
                                        <p className='mb-0 font-size-1 pt-2'>Indulge in clould-like comfort.Choose from soft to maximum support-all here for you.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="collection-item">
                                <div className='position-relative'>
                                    <Link to='/more-product'>
                                     <img src={moreitem} alt="collection item" className='img-fluid'/>
                                    </Link>
                                    <div className="collection-item-content position-absolute text-white">
                                        <h2 className='mb-0 pt-1'>Accessories & More</h2>
                                        <p className='mb-0 font-size-1 pt-2'>Discover the comfort you didn't realize you needed. Elevate your sleep experience now.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <HomeProductCrousel />
        <HomeTabs />
        {/* <Testimonial /> */}
        <Footer />
    </>
  )
}

export default Home
