import alovera from '../assets/img/pillow/alovera.jpg';
import alovera2 from '../assets/img/pillow/alovera2.jpg';
import bliss from '../assets/img/pillow/bliss-pillow.jpg';
import bliss2 from '../assets/img/pillow/bliss-pillow (2).jpg';
import doctor from '../assets/img/pillow/doctor.jpg';
import doctor2 from '../assets/img/pillow/doctor2.jpg';
import harmony from '../assets/img/pillow/harmony.jpg';
import sp from '../assets/img/pillow/sleepy-pillow.jpg';
import star from '../assets/img/pillow/star-pillow.jpg';
import down from '../assets/img/pillow/down feather (2).jpg';
import cloud from '../assets/img/pillow/cloud.jpg';
import cloud2 from '../assets/img/pillow/Cloud pillow.jpg'



const pillowData = [
    {
        category: 'Pillow',
        products: [
            {
                id: '1',
                img: [
                    { main: alovera},
                    { thumb1: alovera2},
                ],
                name: 'Aloe Vera Pillow',
                title: "Aloe Vera Pillow",
                discription: 'Amani has incorporated the highest quality of Aloe vera for fiber coating. Aloe vera works silently through the night improving the quality of your skin and sleep. This is a pillow composed of natural materials with large anti-bacterial properties and made for our customers with sensitive skin. Happy & Beautiful sleeping!',
                sizes: [
                    { size: "17x27 Inch" },
                ],
            },
            {
                id: '2',
                img: [
                    { main: bliss},
                    { thumb1: bliss2},
                ],
                name: 'Bliss Pillow',
                title: "Bliss Pillow",
                discription: 'This pillow is made with poly bre wrapped with premium soft fabric. It comes in pairs so that you and your partner can sleep comfortably and undisturbed.',
                sizes: [
                    { size: "17x27 Inch" },
                ],
            },
            {
                id: '3',
                img: [
                    { main: doctor},
                    { thumb1: doctor2},
                ],
                name: 'Doctor Pillow',
                title: "Doctor Pillow",
                discription: 'Not too soft, not too hard just right! After years of R&D, Amani presents you with the most recommended pillows by Doctors of Orthopaedic and Chiropractors. Dr. Pillow’s 100% natural organic cotton covering with Medicated Microfiber Filing allows the nervous system to work naturally even while you are asleep.',
                sizes: [
                    { size: "17x27 Inch" },
                ],
            },
            {
                id: '4',
                img: [
                    { main: harmony},
                ],
                name: 'Latex Harmony Pillow',
                title: "Latex Harmony Pillow",
                discription: 'Latex Harmony is a unique pillow that provides the natural freshness of latex and the goodness of memory foam all in one product. Latex works tirelessly improving the breathing ability and bring a unique freshness to the sleeper while the Memory Foam works dynamically adjusting to the shape of the customer.',
                sizes: [
                    { size: "17x27 Inch" },
                ],
            },
            {
                id: '5',
                img: [
                    { main: sp},
                ],
                name: 'Sleepy Pillow',
                title: "Sleepy Pillow",
                discription: '',
                sizes: [
                    { size: "17x27 Inch" },
                ],
            },
            {
                id: '6',
                img: [
                    { main: star},
                ],
                name: 'Star Pillow',
                title: "Star Pillow",
                discription: "The mighty luxurious Star Pillow is filled with soft microfibers that cocoon your head support like the mother's lap. After a strenuous day at work with most of the stress on your neck, the Star Luxury pillow is your perfect sleeping companion.",
                sizes: [
                    { size: "17x27 Inch" },
                ],
            },
            {
                id: '7',
                img: [
                    { main: down},
                ],
                name: 'Down Feather Pillow',
                title: "Down Feather",
                discription: "This pillow is made with all-natural materials and self-lifting characteristics. It provides a royal, luxurious experience and reduces neck strain while sleeping. Ideal for those who love soft sleeping surfaces.",
                sizes: [
                    { size: "17x27 Inch" },
                    { size: "20x30 Inch" }
                ],
            },
            {
                id: '8',
                img: [
                    { main: cloud},
                    { thumb1: cloud2}
                ],
                name: 'Cloud Pillow',
                title: "Cloud",
                discription: "One of a kind filled with Down-Feather alternative filling. Cloud pillow is made with 0.7 Denier imported virgin super microfiber. When you sleep on our Cloud pillow, it will give you a Floating in the Air Experience.",
                sizes: [
                    { size: "17x27 Inch" },
                ],
            }
        ]
    },
    
];


export default pillowData;
